<template>
    <div data-view="edit-user">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <card shadow type="secondary" footer-classes="position-sticky bottom-0">
                <div slot="header" class="bg-white border-0 d-flex justify-content-between align-items-center">
                    <h3 class="mb-0">{{ isNew ? 'Neuen Benutzer anlegen' : 'Benutzer bearbeiten' }}</h3>
                </div>
                <template>
                    <form id="edit-form" @submit="submit" @change="formChanged" ref="form">
                        <h6 class="heading-small text-muted mb-4">Benutzerdaten</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Name"
                                                placeholder="Name"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.name.$model"
                                                :valid="showErrors ? !$v.form.name.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.name.required">Bitte geben Sie einen Namen an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="E-Mail-Adresse"
                                                placeholder="E-Mail-Adresse"
                                                type="email"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.email.$model"
                                                :valid="showErrors ? !$v.form.email.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.email.required">Bitte geben Sie eine E-Mail-Adresse an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="showErrors && !$v.form.email.email">Die angegebene E-Mail-Adresse ist ungültig.</div>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">Zugangsdaten</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Neues Passwort"
                                                placeholder="Neues Passwort"
                                                type="password"
                                                input-classes="form-control-alternative"
                                                :required="isNew"
                                                v-model="$v.form.password.$model"
                                                :valid="validatePassword && showErrors ? !$v.form.password.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="validatePassword && showErrors && !$v.form.password.required">Bitte geben Sie ein Passwort an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="validatePassword && showErrors && !$v.form.password.minLength">Das Passwort muss mindestens 8 Zeichen lang sein.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Neues Passwort wiederholen"
                                                placeholder="Neues Passwort wiederholen"
                                                type="password"
                                                input-classes="form-control-alternative"
                                                :required="isNew"
                                                v-model="$v.form.passwordConfirm.$model"
                                                :valid="validatePassword && showErrors ? !$v.form.passwordConfirm.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="validatePassword && showErrors && !$v.form.passwordConfirm.required">Bitte bestätigen Sie Ihr Passwort.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="validatePassword && showErrors && !$v.form.passwordConfirm.sameAsPassword">Die angegebenen Passwörter stimmen nicht überein.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <base-input label="API-Key"
                                                placeholder="API-Key"
                                                input-classes="form-control-alternative"
                                                :readonly="true"
                                                v-model="form.api_token"
                                    />
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">2-Faktor Authentifizierung</h6>
                        <div class="pl-lg-4">
                            <base-button type="primary" nativeType="button" icon="ni ni-key-25" @click="resetTOTP">Zurücksetzen</base-button>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">Zugriffsrechte</h6>
                        <div class="pl-lg-4">
                            <base-checkbox class="mb-3" v-model="form.login_available">
                                Hat Zugang zum Adminbereich
                            </base-checkbox>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">E-Mail-Einstellungen</h6>
                        <div class="pl-lg-4">
                            <base-checkbox class="mb-3" v-model="form.email_unanswered_bookings">
                                Erhält E-Mails über nicht bearbeitete Buchungsanfragen
                            </base-checkbox>
                        </div>
                    </form>
                </template>
                <template slot="footer">
                    <base-button type="primary" form="edit-form" nativeType="submit" :icon="spinners.submitButton ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'">
                        Speichern
                    </base-button>

                    <base-button type="primary" form="edit-form" :icon="spinners.submitAndClose ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'" @click="submitAndClose">
                        Speichern &amp; schließen
                    </base-button>

                    <base-button type="link" form="edit-form" icon="ni ni-fat-remove" @click="close">Schließen</base-button>
                </template>
            </card>
        </div>
    </div>
</template>
<script>
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import apiService from '@/apiService'
import helper from '@/helper'
import eventBus from '@/eventBus'

export default {
    name: 'edit-user',
    props: {
        user: {
            type: Object,
            default() {
                return {
                    id: null,
                    name: '',
                    email: '',
                    password: '',
                    passwordConfirm: '',
                    api_token: '',
                    login_available: false,
                    email_unanswered_bookings: false,
                };
            },
        },
        closeParams: {
            type: Object,
            default() {
                return {
                    name: 'users',
                };
            },
        },
    },
    data() {
        return {
            saveEventId: null,
            closeEventId: null,
            showErrors: false,
            spinners: {
                submitButton: false,
                submitAndClose: false,
            },
            cleanForm: {
                ...this.user,
                password: '',
                passwordConfirm: '',
            },
            form: {
                ...this.user,
                password: '',
                passwordConfirm: '',
            },
        };
    },
    computed: {
        isNew() {
            return this.form.id === null;
        },
        validatePassword() {
            return this.isNew || this.form.password || this.form.passwordConfirm;
        },
    },
    watch: {
        form() {
            this.formChanged();
        },
        cleanForm() {
            this.formChanged();
        },
    },
    validations() {
        if(this.validatePassword) {
            return {
                form: {
                    name: { required },
                    email: { required, email },
                    password: { required, minLength: minLength(8) },
                    passwordConfirm: { required, sameAsPassword: sameAs('password') },
                },
            };
        } else {
            return {
                form: {
                    name: { required },
                    email: { required, email },
                    password: {},
                    passwordConfirm: {},
                },
            };
        }
    },
    methods: {
        async submit(event = null) {
            if(event) event.preventDefault();

            this.spinners.submitButton = this.spinners.submitAndClose ? false : true;

            this.$v.$touch();
            
            if(this.$v.$invalid) {
                this.showErrors = true;
            } else {
                let responseData;

                if(this.isNew) {
                    responseData = await apiService.createEntry('user', this.form);
                } else {
                    const form = {...this.form};

                    if(!this.validatePassword) {
                        delete form.password;
                    }

                    responseData = await apiService.updateEntry('user', this.form.id, form);
                }

                if(responseData) {
                    this.showErrors = false;
                    this.form = {
                        ...responseData,
                        password: '',
                        passwordConfirm: '',
                    };
                    this.cleanForm = {
                        ...responseData,
                        password: '',
                        passwordConfirm: '',
                    };

                    this.$notify({
                        type: 'success',
                        title: 'Daten erfolgreich gespeichert!'
                    });
                }
            }

            this.spinners.submitButton = false;
        },
        formChanged() {
            eventBus.set('unsaveToClose', !helper.deepEqualObjects(this.cleanForm, this.form));
        },
        close() {
            this.$router.push(this.closeParams);
        },
        async saveAndClose() {
            await this.submit();

            return !this.showErrors;
        },
        async submitAndClose() {
            this.spinners.submitAndClose = true;

            await this.submit();
            
            this.spinners.submitAndClose = false;

            if(!this.showErrors) this.close();
        },
        async resetTOTP() {
            if(await apiService.resetTOTP(this.form.id)) {
                this.$notify({
                    type: 'success',
                    title: '2FA erfolgreich zurückgesetzt!'
                });
            }
        },
    },
    created() {
        this.saveEventId = eventBus.on('save-and-close', async () => {
            const success = await this.saveAndClose();

            eventBus.emit('entry-saved', success);
        });

        this.closeEventId = eventBus.on('prepare-to-close', () => {
            this.formChanged();
        });
    },
    beforeDestroy() {
        eventBus.off('save-and-close', this.saveEventId);
        eventBus.off('prepare-to-close', this.closeEventId);
    },
};
</script>
<style></style>
