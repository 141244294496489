<template>
    <base-dropdown tag="div">
        <base-button slot="title" size="sm" type="primary" class="dropdown-toggle">
            Bearbeitungssprache: 
            <i v-if="spinners.language" class="fas fa-circle-notch fa-spin"></i>
            <span v-else>{{ selectedLanguage.name }}</span>
        </base-button>
        <a class="dropdown-item" v-for="language in languages" :key="language.short_code" @click="defaultEditLanguage = language.short_code">{{ language.name }}</a>
    </base-dropdown>
</template>

<script>
import apiService from '@/apiService'
import userSettings from '@/userSettings'
import eventBus from '@/eventBus'

export default {
    name: 'language-menu',
    data() {
        return {
            defaultEditLanguage: userSettings.getSetting('defaultEditLanguage'),
            languages: [],
            spinners: {
                language: false,
            },
        };
    },
    computed: {
        selectedLanguage() {
            return this.languages.find(language => language.short_code === this.defaultEditLanguage) || {short_code: '', name: 'Keine ausgewählt'};
        }
    },
    watch: {
        defaultEditLanguage(newLang, oldLang) {
            const changed = userSettings.getSetting('defaultEditLanguage') !== newLang;

            userSettings.setSetting('defaultEditLanguage', newLang);

            this.$emit('change', oldLang, newLang);
            
            if(changed) {
                eventBus.emit('language-change', newLang);
            }
        }
    },
    async created() {
        eventBus.on('language-change', newLang => {
            this.defaultEditLanguage = newLang;
        });

        this.spinners.language = true;

        const response = await apiService.loadTableData('language', {}, 0);

        if(response !== null) {
            this.languages = response.data;
        }

        this.spinners.language = false;
    },
}
</script>

<style>

</style>