<template>
    <div data-view="statistics">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
            <div class="row">
                <div class="col-lg-6">
                    <stats-card title="Angemeldete Hotels (nur freigeschaltete)"
                                type="gradient-blue"
                                :sub-title="generalStatistics.hotels.total.toString()"
                                icon="ni ni-building"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-primary mr-2">{{ generalStatistics.hotels.last_month }}</span>
                            <span class="text-nowrap">im letzten Monat</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-lg-6">
                    <stats-card title="Durchgeführte Buchungen"
                                type="gradient-orange"
                                :sub-title="generalStatistics.bookings.total.toString()"
                                icon="ni ni-tag"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer">
                            <span class="text-primary mr-2">{{ generalStatistics.bookings.last_month }}</span>
                            <span class="text-nowrap">im letzten Monat</span>
                        </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <hotel-statistics-table title="Statistiken nach Hotel" :startPagination="startPagination" :startSearch="startSearch" :startSorting="startSorting"></hotel-statistics-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HotelStatisticsTable from './Tables/HotelStatisticsTable'
import apiService from '@/apiService';
import userSettings from '@/userSettings'

export default {
    name: 'statistics',
    components: {
        HotelStatisticsTable,
    },
    props: {
        pagination: {
            type: Object,
            default() {
                return {
                    showAll: false,
                    total: 0,
                    perPage: userSettings.getSetting('rowsPerTable'),
                    value: 1,
                };
            },
        },
        search: {
            type: Object,
            default() {
                return {
                    name: '',
                    country: null,
                    state: '',
                    accepted_value: null,
                    accepted_operator: 'gte',
                    answer_value: null,
                    answer_operator: 'gte',
                };
            },
        },
        sorting: {
            type: Object,
            default() {
                return {
                    field: 'name',
                    dir: 'asc',
                };
            },
        },
    },
    data() {
        return {
            startPagination: this.pagination,
            startSearch: this.search,
            startSorting: this.sorting,
            generalStatistics: {
                hotels: {
                    total: 0,
                    last_month: 0,
                },
                bookings: {
                    total: 0,
                    last_month: 0,
                },
            },
            tableData: []
        };
    },
    methods: {
        async loadGenaralStatistics() {
            const response = await apiService.getStatistics();

            if(response !== null) {
                this.generalStatistics = response;
            }
        },
        async loadHotelStatistics() {
            const response = await apiService.getStatisticsForAll('hotel');

            if(response !== null) {
                this.tableData = response;
            }
        },
    },
    created() {
        this.loadGenaralStatistics();
        this.loadHotelStatistics();
    }
};
</script>
<style></style>
