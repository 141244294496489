<template>
  <div data-view="edit-email-template">

    <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <card shadow type="secondary" footer-classes="position-sticky bottom-0">
        <base-input label="Name"
                    placeholder="Name des Templates"
                    input-classes="form-control-alternative"
                    type="text"
                    v-model="form.name"
        />
        <base-input label="Name"
                    placeholder="Betreff des Templates"
                    input-classes="form-control-alternative"
                    type="text"
                    v-model="form.subject"
        />
        <base-input label="Absender"
                    placeholder="Absender"
                    input-classes="form-control-alternative"
                    type="text"
                    v-model="form.from"
        />
        <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="this.error">Bitte geben Sie einen Absender ein.</div>

        <div v-if="this.intern">
          <base-input label="Empfänger"
                      placeholder="Empfänger"
                      input-classes="form-control-alternative"
                      type="text"
                      v-model="form.to"
          />
        </div>
        <base-input label="BCC (Bei mehreren Adressen Kommasepariert eintragen.)"
                    placeholder="test@test.net, test2@test.net"
                    input-classes="form-control-alternative"
                    type="text"
                    v-model="form.cc"
        />
<!--        <quill-editor v-model="form.template"/>-->
        <label for="template">Template</label>
        <textarea id="template" rows="25" class="form-control" v-model="form.template"></textarea>
        <div class="d-flex justify-content-between align-items-center mt-3 pr-2">
          <div>
            <base-button type="primary" size="sm" @click="saveTemplateAndBack()" title="Speichern">
              <i class="ni ni-check-bold"></i>
              Speichern und schließen
            </base-button>
            <base-button type="link" size="sm" @click="back()" title="Speichern">
              <i class="ni ni-fat-remove"></i>
              Schließen
            </base-button>
          </div>
          <div>
            <base-button type="primary" size="sm" @click="showPreview(form)" title="Speichern">
              <i class="fas fa-eye"></i>
               Vorschau
            </base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {required} from 'vuelidate/lib/validators'
import apiService from '@/apiService'
import eventBus from "@/eventBus";
import helper from "@/helper";

export default {
  mounted() {
    console.log(this.template)
  },
  name: 'edit-email-template',
  props: {
    template: {
      type: Object
    },
  },
  data() {
    return {
      form: this.template,
      cleanForm: {...this.template},
      intern: this.template.intern,
      error: false,
    };
  },
  computed: {
    // getValue() {
    //   return this.template.value
    // },
    // getName() {
    //   return this.template.name
    // }
  },
  watch: {
    form() {
      this.formChanged();
    },
    cleanForm() {
      this.formChanged();
    },
  },
  validations: {
    form: {
      name: {required},
      from: {required},
      template: {required},
    },
  },
  methods: {
    validate() {
      if(this.form.from === ""){
        this.error = true;
        return false;
      }else{
        return true;
      }
    },
    formChanged() {
      eventBus.set('unsaveToClose', !helper.deepEqualObjects(this.cleanForm, this.form));
    },
    back() {
      this.$router.push({name: "email-templates", params:{success:false, file:this.form.name}})
    },
    saveTemplate() {
      apiService.setEmailTemplates(this.form, this.form.id)
    },
    saveTemplateAndBack() {
      if(this.validate()){
        this.saveTemplate()
        this.$router.push({name: "email-templates", params:{success:true, file:this.form.name}})
      }
    },
    showPreview(form) {
      this.$router.push({name: "email-preview", params: {template: form}})
    }
  },
};
</script>
<style scoped>
</style>
