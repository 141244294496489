<template>
    <div data-view="edit-booking">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <card shadow type="secondary" footer-classes="position-sticky bottom-0">
                <div slot="header" class="bg-white border-0">
                    <h3 class="mb-0">{{ isNew ? 'Neue Buchung anlegen' : 'Buchung bearbeiten' }}</h3>
                </div>
                <template>
                    <form id="edit-form" @submit="submit" @change="formChanged" ref="form">
                        <h6 class="heading-small text-muted mb-4">Bucher</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Buchungscode"
                                                placeholder="Buchungscode"
                                                input-classes="form-control-alternative"
                                                :required="isNew"
                                                :readonly="!isNew"
                                                v-model="$v.form.bookingcode.$model"
                                                :valid="showErrors ? !$v.form.bookingcode.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.bookingcode.required">Bitte geben Sie einen Buchungscode an.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Name"
                                                placeholder="Name"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_name.$model"
                                                :valid="showErrors ? !$v.form.booker_name.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_name.required">Bitte geben Sie einen Namen an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group has-label">
                                        <label class="form-control-label d-block">Hotel *</label>

                                        <base-dropdown tag="div" class="mb-4">
                                            <base-button slot="title" type="form-control-alternative" :block="true" class="dropdown-toggle">
                                                {{ selectedBookerHotel.name }}
                                            </base-button>
                                            <a class="dropdown-item text-normal" v-for="hotel in hotels" :key="hotel.id" @click="form.booker_hotel = hotel.id">{{ hotel.name }}</a>
                                        </base-dropdown>
                                        <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && form.booker_hotel === null">Bitte wählen Sie ein Hotel aus.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Position"
                                                placeholder="Position"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_position.$model"
                                                :valid="showErrors ? !$v.form.booker_position.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_position.required">Bitte geben Sie einen Position an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="E-Mail-Adresse"
                                                placeholder="E-Mail-Adresse"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_email.$model"
                                                :valid="showErrors ? !$v.form.booker_email.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_email.required">Bitte geben Sie eine E-Mail-Adresse an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="showErrors && !$v.form.booker_email.email">Die angegebene E-Mail-Adresse ist ungültig.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Telefon"
                                                placeholder="Telefonnummer"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_telephone.$model"
                                                :valid="showErrors ? !$v.form.booker_telephone.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_telephone.required">Bitte geben Sie eine Telefonnummer an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Straße"
                                                placeholder="Straße"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_street.$model"
                                                :valid="showErrors ? !$v.form.booker_street.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_street.required">Bitte geben Sie eine Straße an.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Hausnummer"
                                                placeholder="Hausnummer"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_housenumber.$model"
                                                :valid="showErrors ? !$v.form.booker_housenumber.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_housenumber.required">Bitte geben Sie eine Hausnummer an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Postleitzahl"
                                                placeholder="Postleitzahl"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_postalcode.$model"
                                                :valid="showErrors ? !$v.form.booker_postalcode.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_postalcode.required">Bitte geben Sie eine Postleitzahl an.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Stadt"
                                                placeholder="Stadt"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_city.$model"
                                                :valid="showErrors ? !$v.form.booker_city.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_city.required">Bitte geben Sie eine Stadt an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Land"
                                                placeholder="Land"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.booker_country.$model"
                                                :valid="showErrors ? !$v.form.booker_country.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.booker_country.required">Bitte geben Sie ein Land an.</div>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">Anfrage</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group has-label">
                                        <label class="form-control-label d-block">Hotel *</label>

                                        <base-dropdown tag="div" class="mb-4">
                                            <base-button slot="title" type="form-control-alternative" :block="true" class="dropdown-toggle">
                                                {{ selectedRequestHotel.name }}
                                            </base-button>
                                            <a class="dropdown-item text-normal" v-for="hotel in hotels" :key="hotel.id" @click="form.hotel = hotel.id">{{ hotel.name }}</a>
                                        </base-dropdown>
                                        <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && form.hotel === null">Bitte wählen Sie ein Hotel aus.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Ankunft"
                                                type="date"
                                                pattern="\d{4}-\d{2}-\d{2}"
                                                placeholder="Ankunft (JJJJ-MM-TT)"
                                                input-classes="form-control-alternative"
                                                v-model="form.date_arrival"
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Abreise"
                                                type="date"
                                                pattern="\d{4}-\d{2}-\d{2}"
                                                :min="form.date_arrival"
                                                placeholder="Abreise (JJJJ-MM-TT)"
                                                input-classes="form-control-alternative"
                                                v-model="form.date_departure"
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group has-label">
                                        <label class="form-control-label d-block">Zimmer</label>

                                        <div class="d-flex">
                                            <base-input type="number"
                                                        class="mr-2 mb-0"
                                                        placeholder="Anzahl"
                                                        input-classes="form-control-alternative"
                                                        v-model="form.room_one_num"
                                            />

                                            <base-dropdown tag="div" class="flex-fill">
                                                <base-button slot="title" type="form-control-alternative" class="dropdown-toggle text-left w-100">
                                                    {{ getRoomTypeString(form.room_one_type) }}
                                                </base-button>
                                                <a class="dropdown-item text-normal" @click="form.room_one_type = 'SINGLE'">Einzelzimmer</a>
                                                <a class="dropdown-item text-normal" @click="form.room_one_type = 'DOUBLE'">Doppelzimmer</a>
                                            </base-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group has-label">
                                        <label class="form-control-label d-block">Weitere Zimmer</label>

                                        <div class="d-flex">
                                            <base-input type="number"
                                                        class="mr-2 mb-0"
                                                        placeholder="Anzahl"
                                                        input-classes="form-control-alternative"
                                                        v-model="form.room_two_num"
                                            />

                                            <base-dropdown tag="div" class="flex-fill">
                                                <base-button slot="title" type="form-control-alternative" class="dropdown-toggle text-left w-100">
                                                    {{ getRoomTypeString(form.room_two_type) }}
                                                </base-button>
                                                <a class="dropdown-item text-normal" @click="form.room_two_type = 'SINGLE'">Einzelzimmer</a>
                                                <a class="dropdown-item text-normal" @click="form.room_two_type = 'DOUBLE'">Doppelzimmer</a>
                                            </base-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group has-label">
                                        <label class="form-control-label">
                                            Kommentar
                                        </label>
                                        
                                        <textarea placeholder="Kommentar" class="form-control form-control-alternative" v-model="form.comments"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">Bearbeiter</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Name"
                                                placeholder="Name"
                                                input-classes="form-control-alternative"
                                                v-model="form.editor_name"
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="E-Mail-Adresse"
                                                placeholder="E-Mail-Adresse"
                                                input-classes="form-control-alternative"
                                                v-model="$v.form.editor_email.$model"
                                                :valid="showErrors && $v.form.editor_email.$model ? !$v.form.editor_email.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && $v.form.editor_email.$model && !$v.form.editor_email.email">Die angegebene E-Mail-Adresse ist ungültig.</div>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">Antwort</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group has-label">
                                        <label class="form-control-label d-block">Entscheidung</label>

                                        <base-dropdown tag="div">
                                            <base-button slot="title" type="form-control-alternative" class="dropdown-toggle">
                                                {{ getDecisionString(form.decision) }}
                                            </base-button>
                                            <a class="dropdown-item text-normal" @click="form.decision = 'NONE'">Noch keine</a>
                                            <a class="dropdown-item text-normal" @click="form.decision = 'ACCEPTED'">Angenommen</a>
                                            <a class="dropdown-item text-normal" @click="form.decision = 'DECLINED'">Abgelehnt</a>
                                            <a class="dropdown-item text-normal" @click="form.decision = 'OTHER_CONDITIONS'">Zu anderen Konditionen</a>
                                        </base-dropdown>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Schlüssel"
                                                placeholder="Wird automatisch generiert"
                                                input-classes="form-control-alternative"
                                                :readonly="true"
                                                v-model="form.keystring"
                                    />
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Einzelzimmerpreis"
                                                placeholder="Einzelzimmerpreis"
                                                type="number"
                                                input-classes="form-control-alternative"
                                                v-model="form.price_singleroom"
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Doppelzimmerpreis"
                                                placeholder="Doppelzimmerpreis"
                                                type="number"
                                                input-classes="form-control-alternative"
                                                v-model="form.price_doubleroom"
                                    />
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group has-label">
                                        <label class="form-control-label">
                                            Kommentar
                                        </label>
                                        
                                        <textarea placeholder="Kommentar" class="form-control form-control-alternative" v-model="form.editor_comment"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
                <template slot="footer">
                    <base-button type="primary" form="edit-form" nativeType="submit" :icon="spinners.submitButton ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'">
                        Speichern
                    </base-button>

                    <base-button type="primary" form="edit-form" :icon="spinners.submitAndClose ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'" @click="submitAndClose">
                        Speichern &amp; schließen
                    </base-button>

                    <base-button type="link"  form="edit-form" icon="ni ni-fat-remove" @click="close">Schließen</base-button>
                </template>
            </card>
        </div>
    </div>
</template>
<script>
import { required, email, decimal } from 'vuelidate/lib/validators'
import apiService from '@/apiService'
import Localized from '@/Localized'
import helper from '@/helper'
import eventBus from '@/eventBus'

export default {
    name: 'edit-booking',
    props: {
        booking: {
            type: Object,
            default() {
                return new Localized({
                    id: null,
                    bookingcode: '',
                    booker_name: '',
                    booker_hotel: null,
                    booker_position: '',
                    booker_email: '',
                    booker_telephone: '',
                    booker_street: '',
                    booker_housenumber: '',
                    booker_postalcode: '',
                    booker_city: '',
                    booker_country: '',
                    editor_name: '',
                    editor_email: '',
                    editor_comment: '',
                    date_arrival: '',
                    date_departure: '',
                    comments: '',
                    keystring: '',
                    room_one_num: 0,
                    room_two_num: 0,
                    room_one_type: 'SINGLE',
                    room_two_type: 'SINGLE',
                    decision: 'NONE',
                    price_singleroom: 0,
                    price_doubleroom: 0,
                    hotel: null,
                });
            },
        },
        closeParams: {
            type: Object,
            default() {
                return {
                    name: 'bookings',
                };
            },
        },
    },
    data() {
        return {
            saveEventId: null,
            closeEventId: null,
            showErrors: false,
            spinners: {
                submitButton: false,
                submitAndClose: false,
                modalButton: false,
            },
            cleanForm: {...this.booking},
            form: this.booking,
            hotels: [],
        };
    },
    computed: {
        isNew() {
            return this.form.id === null;
        },
        selectedBookerHotel() {
            return this.hotels.find(hotel => hotel.id === this.form.booker_hotel) || {name: 'Kein Hotel ausgewählt'};
        },
        selectedRequestHotel() {
            return this.hotels.find(hotel => hotel.id === this.form.hotel) || {name: 'Kein Hotel ausgewählt'};
        },
    },
    watch: {
        form() {
            this.formChanged();
        },
        cleanForm() {
            this.formChanged();
        },
    },
    validations() { 
        const validations = {
            form: {
                bookingcode: { required },
                booker_name: { required },
                booker_position: { required },
                booker_email: { required, email },
                booker_telephone: { required },
                booker_street: { required },
                booker_housenumber: { required },
                booker_postalcode: { required },
                booker_city: { required },
                booker_country: { required },
                date_arrival: { required },
                date_departure: { required },
                room_one_num: { decimal },
                room_two_num: { decimal },
                price_singleroom: { decimal },
                price_doubleroom: { decimal },
                editor_email: {},
            },
        };

        if(this.form.editor_email) {
            validations.form.editor_email = { email };
        }

        return validations;
    },
    methods: {
        async submit(event = null, language = null) {
            if(event) event.preventDefault();

            this.spinners.submitButton = this.spinners.submitAndClose ? false : true;

            this.$v.$touch();
            
            if(this.$v.$invalid || this.form.country === null) {
                this.showErrors = true;
            } else {
                let responseData;

                if(this.isNew) {
                    responseData = await apiService.createEntry('booking', this.form, language);
                } else {
                    responseData = await apiService.updateEntry('booking', this.form.id, this.form, language);
                }

                if(responseData) {
                    this.showErrors = false;
                    this.form = responseData;
                    this.cleanForm = {...responseData};

                    this.$notify({
                        type: 'success',
                        title: 'Daten erfolgreich gespeichert!'
                    });
                }
            }

            this.spinners.submitButton = false;
        },
        formChanged() {
            eventBus.set('unsaveToClose', !helper.deepEqualObjects(this.cleanForm, this.form));
        },
        close() {
            this.$router.push(this.closeParams);
        },
        async saveAndClose() {
            await this.submit();

            return !this.showErrors;
        },
        async submitAndClose() {
            this.spinners.submitAndClose = true;

            await this.submit();
            
            this.spinners.submitAndClose = false;

            if(!this.showErrors) this.close();
        },
        async saveAndReload() {
            this.spinners.modalButton = true;

            await this.submit(null, this.previousLanguage);
            await this.reload();

            this.languageChanged = false;
            this.spinners.modalButton = false;
        },
        async reload() {
            if(this.isNew) {
                this.form = this.booking;
                this.cleanForm = {...this.booking};
            } else {
                const responseData = await apiService.loadEntry('booking', this.form.id);

                if(responseData) {
                    this.showErrors = false;
                    this.form = responseData;
                    this.cleanForm = {...responseData};
                }
            }

            this.languageChanged = false;
        },
        getDecisionString(decision) {
            switch (decision) {
                case 'NONE':
                    return 'Noch keine';
                case 'ACCEPTED':
                    return 'Angenommen';
                case 'DECLINED':
                    return 'Abgelehnt';
                case 'OTHER_CONDITIONS':
                    return 'Zu anderen Konditionen';
            }
        },
        getRoomTypeString(roomType) {
            switch (roomType) {
                case 'SINGLE':
                    return 'Einzelzimmer';
                case 'DOUBLE':
                    return 'Doppelzimmer';
            }
        },
        async loadHotels() {
            const response = await apiService.loadTableData('hotel', {}, 0);

            if(response !== null) {
                this.hotels = response.data;
            }
        },
    },
    created() {
        this.loadHotels();

        this.saveEventId = eventBus.on('save-and-close', async () => {
            const success = await this.saveAndClose();

            eventBus.emit('entry-saved', success);
        });

        this.closeEventId = eventBus.on('prepare-to-close', () => {
            this.formChanged();
        });
    },
    beforeDestroy() {
        eventBus.off('save-and-close', this.saveEventId);
        eventBus.off('prepare-to-close', this.closeEventId);
    },
};
</script>
<style scoped>
</style>
