<template>
    <div data-view="hotel-statistics">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
            <h1 class="text-white">{{ statistics.entry.name }}</h1>

            <div class="row">
                <div class="col-xl-4 mb-4 mb-xl-0">
                    <stats-card title="Buchungsanfragen"
                                type="gradient-orange"
                                :sub-title="statistics.statistics.accepted.total.toString()"
                                icon="ni ni-tag"
                                class="h-100"
                    >
                        <template slot="footer">
                            <span class="text-nowrap">seit Registrierung des Hotels</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-4 mb-4 mb-xl-0">
                    <stats-card title="Akzeptierte Buchungsanfragen"
                                type="gradient-green"
                                :sub-title="statistics.statistics.accepted.accepted.toString()"
                                icon="ni ni-tag"
                                class="h-100"
                    >
                        <template slot="footer">
                            <span class="text-primary mr-2">{{ statistics.statistics.accepted.percentage }}%</span>
                            <span class="text-nowrap">wurden akzeptiert</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-4 mb-4 mb-xl-0">
                    <stats-card title="Durchschnittliche Antwortzeit"
                                type="gradient-red"
                                :sub-title="getAnswerTime(statistics.statistics.answer_time)"
                                icon="ni ni-watch-time"
                                class="h-100"
                    >
                        <template slot="footer">
                            <span class="text-nowrap">Unbeantwortete ausgeschlossen</span>
                        </template>
                    </stats-card>

                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7 mb-4">
            <div class="row">
                <div class="col-12">
                    <card header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h5 class="ls-1 mb-1">Buchungsanfragen</h5>
                            </div>
                            <div class="col">
                                <ul class="nav nav-pills justify-content-end">
                                    <li class="nav-item mr-2 mr-md-0">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: timespan === 'all_time'}"
                                           @click.prevent="reload('all_time')">
                                            <span>Insgesamt</span>
                                        </a>
                                    </li>
                                    <li class="nav-item mr-2 mr-md-0">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: timespan === 'last_year'}"
                                           @click.prevent="reload('last_year')">
                                            <span>Dieses Jahr</span>
                                        </a>
                                    </li>
                                    <li class="nav-item mr-2 mr-md-0">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: timespan === 'last_month'}"
                                           @click.prevent="reload('last_month')">
                                            <span>Diesen Monat</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link py-2 px-3"
                                           href="#"
                                           :class="{active: timespan === 'last_week'}"
                                           @click.prevent="reload('last_week')">
                                            <span>Diese Woche</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <bar-chart v-if="bookingStatistic.chartData"
                                :height="350"
                                ref="barChart"
                                :chart-data="bookingStatistic.chartData"
                                :extraOptions="bookingStatistic.extraOptions"
                        />
                        <div v-else>Keine Daten vorhanden</div>
                    </card>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <base-button type="white" icon="ni ni-fat-remove" @click="close">Schließen</base-button>
        </div>
    </div>
</template>
<script>
import BarChart from '@/components/Charts/BarChart';
import apiService from '@/apiService'

export default {
    name: 'hotel-statistics',
    components: {
        BarChart,
    },
    props: {
        statistics: Object,
        closeParams: Object,
    },
    data() {
        return {
            timespan: 'all_time',
            bookingStatistic: {
                chartData: null,
                extraOptions: {
                    scales: {
                        xAxes: [{ stacked: true }],
                        yAxes: [{ stacked: true }],
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            usePointStyle: false,
                            padding: 16
                        }
                    },
                },
            },
        };
    },
    methods: {
        async reload(timespan) {
            this.timespan = timespan;
            this.bookingStatistic.chartData = null;

            const response = await apiService.getStatisticsForOne('hotel', this.statistics.entry.id, this.timespan);

            if(response !== null && response.statistics.bookings.length !== 0) {
                const date = new Date();
                let labels = [];
                let getIndex;

                switch(timespan) {
                    case 'all_time':
                        response.statistics.bookings.forEach(booking => {
                            const created = new Date(booking.created_at);

                            if(!labels.includes(created.getFullYear())) {
                                labels = [...labels, created.getFullYear()];
                            }
                        });

                        getIndex = date => {
                            return labels.indexOf(date.getFullYear());
                        };
                        break;
                    case 'last_year':
                        labels = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'].map(month => `${month} ${date.getFullYear()}`);

                        getIndex = date => {
                            return date.getMonth();
                        };
                        break;
                    case 'last_month': {
                        const days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

                        labels = ['01. - 05.', '06. - 10.', '11. - 15.', '16. - 20.', '21. - 25.', `26. - ${days}.`].map(day => `${day}${date.getMonth()+1}`);

                        getIndex = date => {
                            return Math.max(6, Math.ceil(date.getDate() / 5)) - 1;
                        };
                        break;
                    }
                    case 'last_week':
                        labels = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                        
                        getIndex = date => {
                            const day = date.getDay();

                            if(day === 0) return 6;
                            return day - 1;
                        };
                        break;
                }
                
                let accepted = new Array(labels.length).fill(0);
                let declined = new Array(labels.length).fill(0);
                let unfinished = new Array(labels.length).fill(0);

                response.statistics.bookings.forEach(booking => {
                    const created = new Date(booking.created_at);
                    const index = getIndex(created);
                    
                    switch(booking.decision) {
                        case 'NONE':
                        default:
                            unfinished[index] += 1;
                            break;
                        case 'ACCEPTED':
                        case 'OTHER_CONDITIONS':
                            accepted[index] += 1;
                            break;
                        case 'DECLINED':
                            declined[index] += 1;
                            break;
                    }
                });

                this.bookingStatistic.chartData = {
                    labels,
                    datasets: [
                        {
                            label: 'Angenommen',
                            backgroundColor: '#2dce89',
                            data: accepted,
                        },
                        {
                            label: 'Abgelehnt',
                            backgroundColor: '#f5365c',
                            data: declined,
                        },
                        {
                            label: 'Unbeantwortet',
                            backgroundColor: '#172b4d',
                            data: unfinished,
                        },
                    ],
                };
            }
        },
        getAnswerTime(seconds) {
            const pad = number => {
                if(number < 10) return `0${number}`;
                return number;
            };

            const minutes = Math.floor(seconds / 60 % 60);
            const hours = Math.floor(seconds / 60 / 60);

            return `${hours}h ${pad(minutes)}m`;
        },
        close() {
            this.$router.push(this.closeParams);
        },
    },
    created() {
        this.reload(this.timespan);
    },
};
</script>
<style></style>
