<template>
        <div class="row justify-content-center" data-view="login">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5">
                        <h1 class="card-title mb-4">Login Adminbereich</h1>

                        <h3 class="mt--3 mb-4 text-danger" v-if="error">{{ error }}</h3>

                        <form role="form" class="pt-4" @submit="submit">
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="E-Mail-Adresse"
                                        type="email"
                                        addon-left-icon="ni ni-email-83"
                                        autofocus
                                        v-model="$v.form.email.$model"
                                        :required="true"
                                        :valid="showErrors ? !$v.form.email.$error : null">
                            </base-input>
                            <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.email.required">Bitte geben Sie eine E-Mail-Adresse an.</div>
                            <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="showErrors && !$v.form.email.email">Die angegebene E-Mail-Adresse ist falsch.</div>

                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Passwort"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="$v.form.password.$model"
                                        :required="true"
                                        :valid="showErrors ? !$v.form.password.$error : null">
                            </base-input>
                            
                            <base-input v-if="totpRequired"
                                        class="input-group-alternative mb-3"
                                        placeholder="TOTP"
                                        type="password"
                                        addon-left-icon="ni ni-key-25"
                                        v-model="$v.form.totp.$model"
                                        :required="totpRequired"
                                        :valid="showErrors ? !$v.form.totp.$error : null">
                            </base-input>

                            <div class="text-center pt-4">
                                <base-button type="primary" nativeType="submit">
                                    <i v-if="spinners.submitButton" class="fas fa-circle-notch fa-spin"></i>
                                    Anmelden
                                </base-button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="mt-3">
                    <router-link to="/forgot-password" class="text-light"><small>Passwort vergessen?</small></router-link>
                </div> -->
            </div>
        </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import apiService from '@/apiService'

export default {
    name: 'login',
    data() {
        return {
            showErrors: false,
            spinners: {
                submitButton: false,
            },
            error: '',
            form: {
                email: '',
                password: '',
                totp: '',
            },
            totpRequired: false,
        }
    },
    validations: {
        form: {
            email: {
                required,
                email
            },
            password: {
                required,
            },
            totp: {},
        },
    },
    methods: {
        async submit(event) {
            event.preventDefault();

            this.spinners.submitButton = true;

            this.$v.$touch();

            if(this.$v.$invalid) {
                this.showErrors = true;
            } else {
                const loginStatus = await apiService.login(this.form.email, this.form.password, this.form.totp);

                if(!loginStatus.login && loginStatus.totp_required) {
                    this.totpRequired = true;
                }
                else if(!loginStatus.login) {
                    this.form.email = '';
                    this.form.password = '';
                    this.form.totp = '';
                    this.error = 'E-Mail-Adresse, Passwort oder TOTP stimmen nicht überein.';
                }
                else {
                    this.$router.push('dashboard');
                }
            }

            this.spinners.submitButton = false;
        }
    }
}
</script>

<style>
</style>
