<template>
    <div class="card shadow" data-view="hotels-table">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <language-menu @change="reload" />
                    <base-button type="primary" size="sm" @click="createEntry">Neuer Eintrag</base-button>
                    <base-button v-if="!pagination.showAll" type="primary" size="sm" @click="showAll">Alle anzeigen</base-button>
                    <base-button type="primary" size="sm" @click="reload">Aktualisieren</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="tableData"
                    :column-count="4"
                    :show-spinner="spinners.table">
                <template slot="columns">
                    <th data-column="internal_id">
                        <sorting-label title="pn-Nr." field="internal_id" v-model="sorting" />
                        <base-input placeholder="pn-Nr. suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.internal_id" @input="searchData"></base-input>
                    </th>
                    <th data-column="name">
                        <sorting-label title="Name" field="name" v-model="sorting" />
                        <base-input placeholder="Name suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.name" @input="searchData"></base-input>
                    </th>
                    <th data-column="postalcode">
                        <sorting-label title="PLZ" field="postalcode" v-model="sorting" />
                        <base-input placeholder="PLZ suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.postalcode" @input="searchData"></base-input>
                    </th>
                    <th data-column="city">
                        <sorting-label title="Stadt" field="city" v-model="sorting" />
                        <base-input placeholder="Stadt suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.city" @input="searchData"></base-input>
                    </th>
                    <th data-column="email">
                        <sorting-label title="E-Mail" field="email" v-model="sorting" />
                        <base-input placeholder="E-Mail suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.email" @input="searchData"></base-input>
                    </th>
                    <th data-column="country">
                        <sorting-label title="Land" field="country" v-model="sorting" />

                        <base-dropdown tag="div">
                            <base-button slot="title" size="sm" type="form-control" class="dropdown-toggle">
                                {{ searchCountry.name }}
                            </base-button>
                            <a class="dropdown-item text-normal" @click="search.country = null, searchData()">Auswahl zurücksetzen</a>
                            <a class="dropdown-item text-normal" v-for="country in countries" :key="country.id" @click="search.country = country.id, searchData()">{{ country.name }}</a>
                        </base-dropdown>
                    </th>
                    <th data-column="state">
                        <sorting-label title="Bundesland" field="state" v-model="sorting" />

                        <base-dropdown tag="div">
                            <base-button slot="title" size="sm" type="form-control" class="dropdown-toggle">
                                {{ searchState.name }}
                            </base-button>
                            <a class="dropdown-item text-normal" @click="search.state = null, searchData()">Auswahl zurücksetzen</a>
                            <a class="dropdown-item text-normal" v-for="state in states" :key="state.id" @click="search.state = state.id, searchData()">{{ state.name }}</a>
                        </base-dropdown>
                    </th>
                    <th data-column="visible">
                        Gelistet
                    </th>
                    <th data-column="allowed_booking">
                        Darf buchen
                    </th>
                    <th></th>
                </template>

                <template slot-scope="{row}">
                    <td data-column="internal_id">
                        {{ row.internal_id }}
                        
                        <base-button type="neutral" size="sm" @click="showBookings(row)" title="Buchungen anzeigen">
                            <i class="ni ni-tag text-orange"></i>
                        </base-button>
                        
                        <base-button type="neutral" size="sm" @click="showStatistics(row)" title="Statistik anzeigen">
                            <i class="ni ni-chart-pie-35 text-red"></i>
                        </base-button>
                    </td>

                    <td data-column="name">
                        {{ row.name }}
                    </td>

                    <td data-column="postalcode">
                        {{ row.postalcode }}
                    </td>

                    <td data-column="city">
                        {{ row.city }}
                    </td>

                    <td data-column="email">
                        {{ row.email }}
                    </td>

                    <td data-column="country">
                        {{ getCountryName(row.country) }}
                    </td>

                    <td data-column="state">
                        {{ getStateName(row.state) }}
                    </td>

                    <td data-column="visible">
                        <label class="custom-toggle mb-0 mr-2">
                            <input type="checkbox" :checked="row.visible" @change="toggleVisibility(row)">
                            <span class="custom-toggle-slider rounded-circle"></span>
                        </label>
                    </td>

                    <td data-column="allowed_booking" v-if="row.visible">
                        <label class="custom-toggle mb-0 mr-2">
                            <input type="checkbox" :checked="row.visible" disabled>
                            <span class="custom-toggle-slider rounded-circle"></span>
                        </label>
                    </td>
                    <td data-column="allowed_booking" v-else>
                        <label class="custom-toggle mb-0 mr-2">
                            <input type="checkbox" :checked="row.allowed_booking" @change="toggleAllowedBooking(row)">
                            <span class="custom-toggle-slider rounded-circle"></span>
                        </label>
                    </td>

                    <td class="d-flex justify-content-end align-items-center">
                        <i v-if="!row.translationStatus.translated" class="fas fa-language fa-2x mr-2" title="Noch nicht übersetzt"></i>


                        <base-button size="sm" @click="editEntry(row)">
                            <i class="fas fa-pen"></i>
                        </base-button>
                        
                        <base-button type="danger" size="sm" @click="showDeleteEntryModal(row)">
                            <i class="fas fa-trash"></i>
                        </base-button>
                    </td>
                </template>
            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end">
            <base-pagination v-if="tableData.length && !pagination.showAll" :total="pagination.total" :perPage="pagination.perPage" :value="pagination.value" @input="loadTableData" />
        </div>

        <modal :show.sync="deleteEntry.showModal"
            gradient="danger"
            modal-classes="modal-danger modal-dialog-centered">
            <div class="py-3 text-center">
                <i class="fas fa-trash fa-3x"></i>
                <h4 class="heading mt-4">Eintrag "{{ deleteEntry.title }}" wirklich löschen?</h4>
                <p class="mb-0">Das Löschen des Eintrags kann nicht rückgängig gemacht werden.</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="deleteSelectedEntry(deleteEntry.id)">Eintrag löschen</base-button>
                <base-button type="link"
                             text-color="white"
                             class="ml-auto"
                             @click="deleteEntry.showModal = false">
                    Abbrechen
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
import apiService from '@/apiService'
import LanguageMenu from '@/views/Dashboard/LanguageMenu.vue'
import SortingLabel from '@/components/Table/SortingLabel.vue'

export default {
    name: 'hotels-table',
    components: {
        LanguageMenu,
        SortingLabel,
    },
    props: {
        title: String,
        startPagination: Object,
        startSearch: Object,
        startSorting: Object,
    },
    data() {
        return {
            spinners: {
                table: false,
            },
            deleteEntry: {
                showModal: false,
                title: '',
                id: 0,
            },
            tableData: [],
            table: 'hotel',
            pagination: this.startPagination,
            search: this.startSearch,
            sorting: this.startSorting,
            countries: [],
            states: [],
        }
    },
    computed: {
        searchCountry() {
            return this.countries.find(country => country.id === this.search.country) || {name: 'Land suchen'};
        },
        searchState() {
            return this.states.find(state => state.id === this.search.state) || {name: 'Bundesland suchen'};
        },
    },
    watch: {
        sorting() {
            this.reload();
        },
    },
    methods: {
        reload() {
            this.loadCountriesAndStates();

            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData();
            }
        },
        async showAll() {
            this.tableData = [];
            this.spinners.table = true;

            const response = await apiService.loadTableData(this.table, this.search, 0, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination.showAll = true;
            }

            this.spinners.table = false;
        },
        showDeleteEntryModal(entry) {
            this.deleteEntry = {
                showModal: true,
                title: entry.name,
                id: entry.id,
            };
        },
        async deleteSelectedEntry(id) {
            const deleted = await apiService.deleteEntry(this.table, id);

            if(!deleted) {
                this.$notify({
                    type: 'danger',
                    title: 'Löschung fehlgeschlagen!'
                });
            } else {
                this.$notify({
                    type: 'success',
                    title: 'Eintrag erfolgreich gelöscht!'
                });

                this.deleteEntry = {
                    showModal: false,
                    title: '',
                    id: 0,
                };

                this.loadTableData();
            }
        },
        async loadTableData(page = null) {
            if(this.pagination.showAll) {
                return this.showAll();
            }

            this.tableData = [];
            this.spinners.table = true;

            const pageToLoad = page || this.pagination.value;

            const response = await apiService.loadTableData(this.table, this.search, pageToLoad, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination = {
                    showAll: false,
                    total: response.total,
                    perPage: response.per_page,
                    value: response.current_page,
                };
            }

            this.spinners.table = false;
        },
        searchData() {
            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData(1);
            }
        },
        createEntry() {
            this.$router.push({name: 'hotel', params: {
                closeParams: {
                    name: 'hotels',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
        editEntry(entry) {
            this.$router.push({name: 'hotel', params: {
                hotel: entry,
                closeParams: {
                    name: 'hotels',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
        async loadCountriesAndStates() {
            let [countriesResponse, statesResponse] = await Promise.all([
                apiService.loadTableData('country', {}, 0),
                apiService.loadTableData('state', {}, 0)
            ]);

            if(countriesResponse !== null) {
                this.countries = countriesResponse.data;
            }
            if(statesResponse !== null) {
                this.states = statesResponse.data;
            }
        },
        getCountryName(countryId) {
            const country = this.countries.find(country => country.id === countryId);

            if(typeof country === 'undefined') return '';

            return country.name;
        },
        getStateName(stateId) {
            const state = this.states.find(state => state.id === stateId);

            if(typeof state === 'undefined') return '';

            return state.name;
        },
        async toggleVisibility(entry) {
            const responseData = await apiService.updateEntry('hotel', entry.id, {visible: !entry.visible});

            if(responseData) {
                entry.visible = !entry.visible;

                this.$notify({
                    type: 'success',
                    title: `Hotel ${entry.name} wurde ${entry.visible ? 'freigeschaltet' : 'deaktiviert'}!`,
                });
            } else {
                this.$notify({
                    type: 'error',
                    title: 'Änderung fehlgeschlagen!'
                });
            }
        },
        async toggleAllowedBooking(entry) {
            const responseData = await apiService.updateEntry('hotel', entry.id, {allowed_booking: !entry.allowed_booking});

            if(responseData) {
                entry.allowed_booking = !entry.allowed_booking;

                this.$notify({
                    type: 'success',
                    title: `Hotel ${entry.name} darf jetzt ${entry.allowed_booking ? 'buchen' : 'nicht mehr buchen'}!`,
                });
            } else {
                this.$notify({
                    type: 'error',
                    title: 'Änderung fehlgeschlagen!'
                });
            }
        },
        showBookings(entry) {
            this.$router.push({
                name: 'bookings',
                params: {
                    search: {
                        hotel__name: entry.name,
                    },
                },
            });
        },
        async showStatistics(entry) {
            const response = await apiService.getStatisticsForOne(this.table, entry.id);

            await this.$router.push({
                name: 'hotel-statistics',
                params: {
                statistics: response,
                closeParams: {
                    name: 'hotels',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
    },
    created() {
        this.loadCountriesAndStates();
        this.loadTableData();
    }
}
</script>
<style>
</style>
