<template>
    <div data-view="users">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <users-table title="Benutzer" :startPagination="startPagination" :startSearch="startSearch" :startSorting="startSorting"></users-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UsersTable from './Tables/UsersTable'
import userSettings from '@/userSettings'

export default {
    name: 'users',
    components: {
        UsersTable
    },
    props: {
        pagination: {
            type: Object,
            default() {
                return {
                    showAll: false,
                    total: 0,
                    perPage: userSettings.getSetting('rowsPerTable'),
                    value: 1,
                };
            },
        },
        search: {
            type: Object,
            default() {
                return {
                    name: '',
                    email: '',
                };
            },
        },
        sorting: {
            type: Object,
            default() {
                return {
                    field: 'name',
                    dir: 'asc',
                };
            },
        },
    },
    data() {
        return {
            startPagination: this.pagination,
            startSearch: this.search,
            startSorting: this.sorting,
        };
    },
};
</script>
<style></style>
