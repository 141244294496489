<template>
    <div class="card shadow" data-view="users-table">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button type="primary" size="sm" @click="createEntry">Neuer Eintrag</base-button>
                    <base-button v-if="!pagination.showAll" type="primary" size="sm" @click="showAll">Alle anzeigen</base-button>
                    <base-button type="primary" size="sm" @click="reload">Aktualisieren</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="tableData"
                    :column-count="3"
                    :show-spinner="spinners.table">
                <template slot="columns">
                    <th data-column="name">
                        <sorting-label title="Name" field="name" v-model="sorting" />
                        <base-input placeholder="Name suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.name" @input="searchData"></base-input>
                    </th>
                    <th data-column="email">
                        <sorting-label title="E-Mail" field="email" v-model="sorting" />
                        <base-input placeholder="E-Mail suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.email" @input="searchData"></base-input>
                    </th>
                    <th></th>
                </template>

                <template slot-scope="{row}">
                    <td data-column="name">
                        {{ row.name }}
                    </td>

                    <td data-column="email">
                        {{ row.email }}
                    </td>

                    <td class="text-right">
                        <base-button size="sm" @click="editEntry(row)">
                            <i class="fas fa-pen"></i>
                        </base-button>
                        
                        <base-button type="danger" size="sm" @click="showDeleteEntryModal(row)">
                            <i class="fas fa-trash"></i>
                        </base-button>
                    </td>
                </template>
            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end">
            <base-pagination v-if="tableData.length && !pagination.showAll" :total="pagination.total" :perPage="pagination.perPage" :value="pagination.value" @input="loadTableData" />
        </div>

        <modal :show.sync="deleteEntry.showModal"
                gradient="danger"
                modal-classes="modal-danger modal-dialog-centered">
            <div class="py-3 text-center">
                <i class="fas fa-trash fa-3x"></i>
                <h4 class="heading mt-4">Eintrag "{{ deleteEntry.title }}" wirklich löschen?</h4>
                <p class="mb-0">Das Löschen des Eintrags kann nicht rückgängig gemacht werden.</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="deleteSelectedEntry(deleteEntry.id)">Eintrag löschen</base-button>
                <base-button type="link"
                             text-color="white"
                             class="ml-auto"
                             @click="deleteEntry.showModal = false">
                    Abbrechen
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
import apiService from '@/apiService'
import SortingLabel from '@/components/Table/SortingLabel.vue';

export default {
    name: 'users-table',
    components: {
        SortingLabel,
    },
    props: {
        title: String,
        startPagination: Object,
        startSearch: Object,
        startSorting: Object,
    },
    data() {
        return {
            spinners: {
                table: false,
            },
            deleteEntry: {
                showModal: false,
                title: '',
                id: 0,
            },
            tableData: [],
            table: 'user',
            pagination: this.startPagination,
            search: this.startSearch,
            sorting: this.startSorting,
        }
    },
    watch: {
        sorting() {
            this.reload();
        },
    },
    methods: {
        reload() {
            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData();
            }
        },
        async showAll() {
            this.tableData = [];
            this.spinners.table = true;

            const response = await apiService.loadTableData(this.table, this.search, 0, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination.showAll = true;
            }
            
            this.spinners.table = false;
        },
        showDeleteEntryModal(entry) {
            this.deleteEntry = {
                showModal: true,
                title: entry.name,
                id: entry.id,
            };
        },
        async deleteSelectedEntry(id) {
            const deleted = await apiService.deleteEntry(this.table, id);

            if(!deleted) {
                this.$notify({
                    type: 'danger',
                    title: 'Löschung fehlgeschlagen!'
                });
            } else {
                this.$notify({
                    type: 'success',
                    title: 'Eintrag erfolgreich gelöscht!'
                });

                this.deleteEntry = {
                    showModal: false,
                    title: '',
                    id: 0,
                };

                this.loadTableData();
            }
        },
        async loadTableData(page = null) {
            if(this.pagination.showAll) {
                return this.showAll();
            }
            
            this.tableData = [];
            this.spinners.table = true;

            const pageToLoad = page || this.pagination.value;

            const response = await apiService.loadTableData(this.table, this.search, pageToLoad, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination = {
                    showAll: false,
                    total: response.total,
                    perPage: response.per_page,
                    value: response.current_page,
                };
            }
            
            this.spinners.table = false;
        },
        searchData() {
            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData(1);
            }
        },
        createEntry() {
            this.$router.push({name: 'user', params: {
                closeParams: {
                    name: 'users',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
        editEntry(entry) {
            this.$router.push({name: 'user', params: {
                user: entry,
                closeParams: {
                    name: 'users',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
    },
    created() {
        this.loadTableData();
    }
}
</script>
<style>
</style>
