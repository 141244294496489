<template>
    <div data-view="user-profile">
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
                     style="min-height: 600px;">
            <!-- Mask -->
            <span class="mask bg-gradient-perso opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-12">
                        <h1 class="display-2 text-white">Hallo {{ user.name }}</h1>
                        <p class="text-white mt-0 mb-5">Willkommen im Personights Adminbereich. Hier können Sie Ihr Profil bearbeiten.</p>
                    </div>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <card shadow type="secondary" footer-classes="position-sticky bottom-0">
                <div slot="header" class="bg-white border-0">
                    <h3 class="mb-0">Mein Profil</h3>
                </div>
                <template>
                    <form id="edit-form" @submit="submit">
                        <h6 class="heading-small text-muted mb-4">Benutzerdaten</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Name"
                                                placeholder="Name"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.name.$model"
                                                :valid="showErrors ? !$v.form.name.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.name.required">Bitte geben Sie einen Namen an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="E-Mail-Adresse"
                                                placeholder="E-Mail-Adresse"
                                                type="email"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model="$v.form.email.$model"
                                                :valid="showErrors ? !$v.form.email.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.email.required">Bitte geben Sie eine E-Mail-Adresse an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="showErrors && !$v.form.email.email">Die angegebene E-Mail-Adresse ist ungültig.</div>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">Passwort</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Neues Passwort"
                                                placeholder="Neues Passwort"
                                                type="password"
                                                input-classes="form-control-alternative"
                                                v-model="$v.form.password.$model"
                                                :valid="validatePassword && showErrors ? !$v.form.password.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="validatePassword && showErrors && !$v.form.password.required">Bitte geben Sie ein Passwort an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="validatePassword && showErrors && !$v.form.password.minLength">Das Passwort muss mindestens 8 Zeichen lang sein.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Neues Passwort wiederholen"
                                                placeholder="Neues Passwort wiederholen"
                                                type="password"
                                                input-classes="form-control-alternative"
                                                v-model="$v.form.passwordConfirm.$model"
                                                :valid="validatePassword && showErrors ? !$v.form.passwordConfirm.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="validatePassword && showErrors && !$v.form.passwordConfirm.required">Bitte bestätigen Sie Ihr Passwort.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="validatePassword && showErrors && !$v.form.passwordConfirm.sameAsPassword">Die angegebenen Passwörter stimmen nicht überein.</div>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">2-Faktor Authentifizierung</h6>
                        <div class="pl-lg-4">
                            <base-button type="primary" nativeType="button" icon="ni ni-key-25" @click="updateTOTP">Aktualisieren</base-button>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">Zugriffsrechte</h6>
                        <div class="pl-lg-4">
                            <base-checkbox class="mb-3" v-model="form.loginAvailable" :disabled="true">
                                Hat Zugang zum Adminbereich
                            </base-checkbox>
                        </div>

                        <hr class="my-4" />

                        <h6 class="heading-small text-muted mb-4">E-Mail-Einstellungen</h6>
                        <div class="pl-lg-4">
                            <base-checkbox class="mb-3" v-model="$v.form.email_unanswered_bookings.$model">
                                Erhält E-Mails über nicht bearbeitete Buchungsanfragen
                            </base-checkbox>
                        </div>
                    </form>
                </template>
                
                <template slot="footer">
                    <base-button type="primary" form="edit-form" nativeType="submit" :icon="spinners.submitButton ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'">
                        Speichern
                    </base-button>

                    <base-button type="primary" form="edit-form" :icon="spinners.submitAndClose ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'" @click="submitAndClose">
                        Speichern &amp; schließen
                    </base-button>

                    <base-button type="link" form="edit-form" icon="ni ni-fat-remove" @click="close">Schließen</base-button>
                </template>
            </card>
        </div>

        <modal :show="totpQrCode !== ''"
               gradient="primary"
               modal-classes="modal-danger modal-dialog-centered">
            <div class="py-3 text-center">
                <i class="ni ni-key-25 ni-3x"></i>
                <h4 class="heading mt-4">Bitte scannen Sie den QR-Code mit Ihrem Authentifikator</h4>
                <img :src="totpQrCode">
            </div>

            <template slot="footer">
                <base-button type="white" @click="totpQrCode = ''">
                    Schließen
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import apiService from '@/apiService'

export default {
    name: 'user-profile',
    data() {
        return {
            user: apiService.getUser(),
            showErrors: false,
            spinners: {
                submitButton: false,
                submitAndClose: false,
            },
            form: {
                name: apiService.getUser().name,
                email: apiService.getUser().email,
                password: '',
                passwordConfirm: '',
                loginAvailable: apiService.getUser().login_available,
                email_unanswered_bookings: apiService.getUser().email_unanswered_bookings,
            },
            totpQrCode: '',
        }
    },
    computed: {
        validatePassword() {
            return this.form.password || this.form.passwordConfirm;
        }
    },
    validations() {
        const validations = {
            form: {
                name: { required },
                email: { required, email },
                password: {},
                passwordConfirm: {},
                email_unanswered_bookings: {},
            },
        };

        if(this.validatePassword) {
            validations.form.password = { required, minLength: minLength(8) };
            validations.form.passwordConfirm = { required, sameAsPassword: sameAs('password') };
        }

        return validations;
    },
    methods: {
        async submit(event = null) {
            if(event) event.preventDefault();

            this.spinners.submitButton = this.spinners.submitAndClose ? false : true;

            this.$v.$touch();
            
            if(this.$v.$invalid) {
                this.showErrors = true;
            } else {
                const status = await apiService.updateProfile(this.form);

                if(status) {
                    this.user = apiService.getUser();
                    this.showErrors = false;
                    this.form = {
                        name: apiService.getUser().name,
                        email: apiService.getUser().email,
                        password: '',
                        passwordConfirm: '',
                        loginAvailable: apiService.getUser().login_available,
                        isAdmin: false,
                        email_unanswered_bookings: apiService.getUser().email_unanswered_bookings,
                    };

                    this.$notify({
                        type: 'success',
                        title: 'Daten erfolgreich gespeichert!'
                    });
                }
            }

            this.spinners.submitButton = false;
        },
        close() {
            this.$router.push({
                name: 'dashboard',
            });
        },
        async submitAndClose() {
            this.spinners.submitAndClose = true;

            await this.submit();
            
            this.spinners.submitAndClose = false;

            if(!this.showErrors) this.close();
        },
        async updateTOTP() {
            const data = await apiService.updateTOTP();

            this.totpQrCode = `data:image/svg+xml;base64,${data.qr_code}`;
        },
    },
};
</script>
<style></style>
