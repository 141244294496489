<template>
    <div class="card shadow" data-view="bookings-table">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-dropdown tag="div">
                        <base-button slot="title" size="sm" type="primary" class="dropdown-toggle">Exportieren...</base-button>
                        <a class="dropdown-item" @click="exportTableData(['booker_email'], {newsletter: 1})">Newsletter-Abonnenten (nur E-Mail-Adressen)</a>
                    </base-dropdown>
                    <base-button type="primary" size="sm" @click="createEntry">Neuer Eintrag</base-button>
                    <base-button v-if="!pagination.showAll" type="primary" size="sm" @click="showAll">Alle anzeigen</base-button>
                    <base-button type="primary" size="sm" @click="reload">Aktualisieren</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="tableData"
                    :column-count="4"
                    :show-spinner="spinners.table">
                <template slot="columns">
                    <th data-column="booker_name">
                        <sorting-label title="Bucher Name" field="booker_name" v-model="sorting" />
                        <base-input placeholder="Name suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.booker_name" @input="searchData"></base-input>
                    </th>
                    <th data-column="booker_hotel__internal_id">
                        <sorting-label title="pn-Nr." field="booker_hotel__internal_id" v-model="sorting" />
                        <base-input placeholder="pn-Nr. suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.booker_hotel__internal_id" @input="searchData"></base-input>
                    </th>
                    <th data-column="booker_hotel__name">
                        <sorting-label title="Bucher Hotel" field="booker_hotel__name" v-model="sorting" />
                        <base-input placeholder="Hotel suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.booker_hotel__name" @input="searchData"></base-input>
                    </th>
                    <th data-column="date_arrival">
                        <sorting-label title="Reisezeitraum" field="date_arrival" v-model="sorting" />
                        <div class="d-flex align-items-center">
                            <span class="mr-2">Von:</span>
                            <base-input placeholder="Ankunft" type="date" inputClasses="form-control-sm" :noMargin="true" v-model="search.date_arrival" @input="searchData"></base-input>
                            <span class="mx-2">Bis:</span>
                            <base-input placeholder="Abreise" type="date" inputClasses="form-control-sm" :noMargin="true" v-model="search.date_departure" @input="searchData"></base-input>
                        </div>
                    </th>
                    <th data-column="hotel__internal_id">
                        <sorting-label title="pn-Nr." field="hotel__internal_id" v-model="sorting" />
                        <base-input placeholder="pn-Nr. suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.hotel__internal_id" @input="searchData"></base-input>
                    </th>
                    <th data-column="hotel__name">
                        <sorting-label title="Angefragtes Hotel" field="hotel__name" v-model="sorting" />
                        <base-input placeholder="Hotel suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.hotel__name" @input="searchData"></base-input>
                    </th>
                    <th data-column="hotel__city">
                        <sorting-label title="Stadt" field="hotel__city" v-model="sorting" />
                        <base-input placeholder="Stadt suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.hotel__city" @input="searchData"></base-input>
                    </th>
                    <th data-column="decision">
                        <sorting-label title="Entscheidung" field="decision" v-model="sorting" />

                        <base-dropdown tag="div">
                            <base-button slot="title" size="sm" type="form-control" class="dropdown-toggle">
                                {{ getDecisionString(search.decision) || 'nach Entscheidung filtern' }}
                            </base-button>
                            <a class="dropdown-item text-normal" @click="search.decision = null, searchData()">Auswahl zurücksetzen</a>
                            <a class="dropdown-item text-normal" @click="search.decision = 'NONE', searchData()"><i class="fas fa-minus mr-2"></i> Noch keine</a>
                            <a class="dropdown-item text-normal" @click="search.decision = 'ACCEPTED', searchData()"><i class="fas fa-check text-success mr-2"></i> Angenommen</a>
                            <a class="dropdown-item text-normal" @click="search.decision = 'DECLINED', searchData()"><i class="fas fa-times text-danger mr-2"></i> Abgelehnt</a>
                            <a class="dropdown-item text-normal" @click="search.decision = 'OTHER_CONDITIONS', searchData()"><i class="far fa-circle mr-2"></i> Zu anderen Konditionen</a>
                        </base-dropdown>
                    </th>
                    <th data-column="created_at">
                        <sorting-label title="Anfragedatum" field="created_at" v-model="sorting" />
                        <base-input placeholder="Datum suchen" type="date" inputClasses="form-control-sm" :noMargin="true" v-model="search.created_at" @input="searchData"></base-input>
                    </th>
                    <th></th>
                </template>

                <template slot-scope="{row}">
                    <td data-column="booker_name">
                        {{ row.booker_name }}
                    </td>

                    <td data-column="booker_hotel__internal_id">
                        {{ hotels[row.booker_hotel].internal_id }}
                    </td>

                    <td data-column="booker_hotel__name">
                        {{ hotels[row.booker_hotel].name }}
                    </td>

                    <td data-column="date_arrival">
                        {{ getFormattedDate(row.date_arrival) }} - {{ getFormattedDate(row.date_departure) }}
                    </td>

                    <td data-column="hotel__internal_id">
                        {{ hotels[row.hotel].internal_id }}
                    </td>

                    <td data-column="hotel__name">
                        {{ hotels[row.hotel].name }}
                    </td>

                    <td data-column="hotel__city">
                        {{ hotels[row.hotel].city }}
                    </td>

                    <td data-column="decision">
                        <i class="fas fa-minus mr-2" v-if="row.decision === 'NONE'"></i>
                        <i class="fas fa-check text-success mr-2" v-else-if="row.decision === 'ACCEPTED'"></i>
                        <i class="fas fa-times text-danger mr-2" v-else-if="row.decision === 'DECLINED'"></i>
                        <i class="far fa-circle mr-2" v-else-if="row.decision === 'OTHER_CONDITIONS'"></i>
                        {{ getDecisionString(row.decision) }}
                    </td>

                    <td data-column="created_at">
                        {{ getFormattedDate(row.created_at) }}
                    </td>

                    <td class="d-flex justify-content-end align-items-center">
                        <base-button type="neutral" size="sm" @click="showHotel(row)" title="Hotel bearbeiten">
                            <i class="ni ni-building text-blue"></i>
                        </base-button>

                        <div class="px-2"></div>

                        <base-button size="sm" @click="editEntry(row)">
                            <i class="fas fa-pen"></i>
                        </base-button>
                        
                        <base-button type="danger" size="sm" @click="showDeleteEntryModal(row)">
                            <i class="fas fa-trash"></i>
                        </base-button>
                    </td>
                </template>
            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end">
            <base-pagination v-if="tableData.length && !pagination.showAll" :total="pagination.total" :perPage="pagination.perPage" :value="pagination.value" @input="loadTableData" />
        </div>

        <modal :show.sync="deleteEntry.showModal"
               gradient="danger"
               modal-classes="modal-danger modal-dialog-centered">
            <div class="py-3 text-center">
                <i class="fas fa-trash fa-3x"></i>
                <h4 class="heading mt-4">Eintrag "{{ deleteEntry.title }}" wirklich löschen?</h4>
                <p class="mb-0">Das Löschen des Eintrags kann nicht rückgängig gemacht werden.</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="deleteSelectedEntry(deleteEntry.id)">Eintrag löschen</base-button>
                <base-button type="link"
                             text-color="white"
                             class="ml-auto"
                             @click="deleteEntry.showModal = false">
                    Abbrechen
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
import apiService from '@/apiService'
import SortingLabel from '@/components/Table/SortingLabel.vue';

export default {
    name: 'bookings-table',
    components: {
        SortingLabel,
    },
    props: {
        title: String,
        startPagination: Object,
        startSearch: Object,
        startSorting: Object,
    },
    data() {
        return {
            spinners: {
                table: false,
            },
            deleteEntry: {
                showModal: false,
                title: '',
                id: 0,
            },
            tableData: [],
            table: 'booking',
            hotels: {},
            pagination: this.startPagination,
            search: this.startSearch,
            sorting: this.startSorting,
        }
    },
    watch: {
        tableData(bookings) {
            bookings.forEach(booking => {
                this.loadHotel(booking.hotel);
                this.loadHotel(booking.booker_hotel);
            });
        },
        sorting() {
            this.reload();
        },
    },
    methods: {
        reload() {
            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData();
            }
        },
        async showAll() {
            this.tableData = [];
            this.spinners.table = true;

            const response = await apiService.loadTableData('booking', this.search, 0, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination.showAll = true;
            }
            
            this.spinners.table = false;
        },
        showDeleteEntryModal(entry) {
            this.deleteEntry = {
                showModal: true,
                title: entry.name,
                id: entry.id,
            };
        },
        async deleteSelectedEntry(id) {
            const deleted = await apiService.deleteEntry('booking', id);

            if(!deleted) {
                this.$notify({
                    type: 'danger',
                    title: 'Löschung fehlgeschlagen!'
                });
            } else {
                this.$notify({
                    type: 'success',
                    title: 'Eintrag erfolgreich gelöscht!'
                });

                this.deleteEntry = {
                    showModal: false,
                    title: '',
                    id: 0,
                };

                this.loadTableData();
            }
        },
        async loadTableData(page = null) {
            if(this.pagination.showAll) {
                return this.showAll();
            }
            
            this.tableData = [];
            this.spinners.table = true;

            const pageToLoad = page || this.pagination.value;

            const response = await apiService.loadTableData('booking', this.search, pageToLoad, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination = {
                    showAll: false,
                    total: response.total,
                    perPage: response.per_page,
                    value: response.current_page,
                };
            }
            
            this.spinners.table = false;
        },
        searchData() {
            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData(1);
            }
        },
        createEntry() {
            this.$router.push({name: 'booking', params: {
                closeParams: {
                    name: 'bookings',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
        editEntry(entry) {
            this.$router.push({name: 'booking', params: {
                booking: entry,
                closeParams: {
                    name: 'bookings',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
        async exportTableData(fields = [], search = {}) {
            await apiService.exportCsv('booking', fields, search);
        },
        getDecisionString(decision) {
            switch (decision) {
                case 'NONE':
                    return 'Noch keine';
                case 'ACCEPTED':
                    return 'Angenommen';
                case 'DECLINED':
                    return 'Abgelehnt';
                case 'OTHER_CONDITIONS':
                    return 'Zu anderen Konditionen';
            }
        },
        getFormattedDate(timestamp) {
            return new Date(timestamp).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        },
        async loadHotel(id) {
            if(typeof this.hotels[id] !== 'undefined') return;

            this.$set(this.hotels, id, {name: ''});

            apiService.suppressErrorMessagesForNextRequest();
            const responseData = await apiService.loadEntry('hotel', id);

            if(responseData) {
                this.$set(this.hotels, id, responseData);
            }
        },
        async showHotel(entry) {
            const responseData = await apiService.loadEntry('hotel', entry.hotel);
            
            this.$router.push({name: 'hotel', params: {
                hotel: responseData,
                closeParams: {
                    name: 'bookings',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        }
    },
    created() {
        this.loadTableData();
    }
}
</script>
<style>
</style>
