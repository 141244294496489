<template>
  <div data-view="email-templates">
    <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <div class="container-fluid mt--7">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
          </div>
          <div class="col text-right">

          </div>
        </div>
      </div>
      <card shadow type="secondary" footer-classes="position-sticky bottom-0">
        <div>
          <div class="d-flex justify-content-between">
            <div>
              <h3>Email-Templates</h3>
            </div>
          </div>

          <br>
          <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="templates"
                        :column-count="3">
              <template slot="columns">
                <th data-column="name">
                  Name
                </th>
                <th data-column="name">
                  Betreff
                </th>
                <th data-column="from">
                  Absender
                </th>
                <th data-column="to">
                  Empfänger
                </th>
                <th data-column="cc">
                  Bcc
                </th>
                <th class="d-flex justify-content-end">Bearbeiten</th>
              </template>
              <template slot-scope="{row}">
                <td data-column="name">
                  {{ row.name }}
                </td>
                <td data-column="name">
                  {{ row.subject }}
                </td>
                <td data-column="from">
                  {{ row.from }}
                </td>
                <td data-column="to">
                  {{ row.to }}
                </td>
                <td data-column="bcc">
                  {{ row.cc }}
                </td>
                <td class="d-flex justify-content-end">
                  <base-button size="sm" @click="editTemplate(row)" tag="div">
                    <i class="fas fa-pen"></i>
                  </base-button>
                  <base-button type="primary" size="sm" @click="showPreview(row)" tag="div">
                    <i class="fas fa-eye"></i>
                  </base-button>
                </td>
              </template>
            </base-table>
          </div>


<!--          <div v-for="template in this.templates" :key="template[filename]" :value="template.value">-->
<!--            <div class="w-100 d-flex justify-content-between align-items-center">-->
<!--              <span>{{ template.filename }}</span>-->
<!--              <div>-->
<!--                <base-button size="sm" @click="editTemplate(template)"><i class="fas fa-pen"></i></base-button>-->
<!--                <base-button type="primary" size="sm" @click="showPreview(template)">-->
<!--                  <i class="fas fa-eye"></i>-->
<!--                </base-button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <hr>-->
<!--          </div>-->


        </div>
      </card>
    </div>
    <!--    <modal :show.sync="deleteEntry.showModal"-->
    <!--           gradient="danger"-->
    <!--           modal-classes="modal-danger modal-dialog-centered">-->
    <!--      <div class="py-3 text-center">-->
    <!--        <i class="fas fa-trash fa-3x"></i>-->
    <!--        <h4 class="heading mt-4">Eintrag "{{ deleteEntry.filename }}" wirklich löschen?</h4>-->
    <!--        <p class="mb-0">Das Löschen des Eintrags kann nicht rückgängig gemacht werden.</p>-->
    <!--      </div>-->

    <!--      <template slot="footer">-->
    <!--        <base-button type="white" @click="deleteTemplate(deleteEntry)">Eintrag löschen</base-button>-->
    <!--        <base-button type="link"-->
    <!--                     text-color="white"-->
    <!--                     class="ml-auto"-->
    <!--                     @click="deleteEntry.showModal = false">-->
    <!--          Abbrechen-->
    <!--        </base-button>-->
    <!--      </template>-->
    <!--    </modal>-->
  </div>
</template>
<script>
import apiService from '@/apiService'

export default {
  mounted() {
    this.getTemplates()
    if (this.success) {
      this.$notify({
        type: 'success',
        title: `email-template ${this.file} erfolgreich gespeichert!`,
      });
    }
  },
  name: 'email-templates',
  components: {

  },
  props: {
    success: Boolean,
    file: String,
    startPagination: Object,
    startSearch: Object,
    startSorting: Object,
  },
  data() {
    return {
      spinners: {
        table: false,
      },
      deleteEntry: {
        showModal: false,
        title: '',
        id: 0,
      },
      tableData: [],
      table: 'templates',
      pagination: this.startPagination,
      search: this.startSearch,
      sorting: this.startSorting,
      countries: [],
      states: [],
      templates: this.templates,
      filename: null,
      value: null
    }
  },
  watch: {
    sorting() {
      this.reload();
    },
  },
  methods: {
    showDeleteEntryModal(entry) {
      this.deleteEntry = {
        showModal: true,
        filename: entry.filename,
      };
    },
    async getTemplates() {
      let templates = await apiService.getEmailTemplates()

      this.templates = templates;
      console.log(templates)
    },

    editTemplate(row) {
      // console.log(row)
      this.$router.push({name: "edit-email-template", params: {template: row}})
      // this.$router.push({name: `edit-email-template/${template.name}`})
    },

    async deleteTemplate(template) {
      const result = await apiService.deleteEmailTemplate(template.filename)
      await this.getTemplates();
      this.deleteEntry.showModal = false;
      console.log(result)
    },
    showPreview(template) {
      this.$router.push({
        name: "email-preview", params: {
          template: template,
          completeBack: true
        }
      })
    },
    searchData() {
      if(this.pagination.showAll) {
        this.showAll();
      } else {
        this.getTemplates();
      }
    },
  }
}
</script>
<style>
</style>