<template>
    <div class="mb-1">
        <div class="d-inline-flex" @click="changeSorting">
            <span>{{ title }}</span>

            <span v-if="value.field === field" class="ml-1">
                <i :class="{fas: true, 'fa-sort-up': value.dir === 'asc', 'fa-sort-down': value.dir === 'desc'}"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sorting-label',
    props: {
        title: String,
        field: String,
        value: Object,
    },
    methods: {
        changeSorting() {
            const newValue = {...this.value};

            if(this.value.field !== this.field) {
                newValue.field = this.field;
                newValue.dir = 'asc';
            } else {
                if(this.value.dir === 'asc') {
                    newValue.dir = 'desc';
                } else {
                    newValue.dir = 'asc';
                }
            }

            this.$emit('input', newValue);
        },
    },
}
</script>

<style>

</style>