export default new class {

    deepEqualObjects(object1, object2) {
        const keys1 = Object.keys(object1),
            keys2 = Object.keys(object2);
      
        if(keys1.length !== keys2.length) return false;
      
        for(let key of keys1) {
            const val1 = object1[key],
                val2 = object2[key],
                areObjects = this.isObject(val1) && this.isObject(val2);

            if(areObjects && !this.deepEqualObjects(val1, val2) || !areObjects && val1 !== val2) {
                return false;
            }
        }
      
        return true;
    }

    isObject(testSubject) {
        return testSubject != null && typeof testSubject === 'object';
    }
}