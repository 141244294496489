<template>
  <div data-view="edit-hotel">
    <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="d-flex">
      <div :class="{'split': showEdits}">
        <div class="container-fluid mt--7">
          <card shadow type="secondary" footer-classes="position-sticky bottom-0">
            <div slot="header" class="bg-white border-0 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">{{ isNew ? 'Neues Hotel anlegen' : 'Hotel bearbeiten' }}</h3>

              <div>
                <!--                <base-button type="icon-only" size="sm" @click="showEditsFunc()" title="Zeige mir die Editierten Daten">-->
                <!--                  <i class="fas fa-pen"></i>-->
                <!--                </base-button>-->

                <base-button type="icon-only" size="sm" @click="showBookings()" title="Buchungen anzeigen">
                  <i class="ni ni-tag text-orange"></i>
                </base-button>

                <base-button type="icon-only" size="sm" @click="showStatistics()" title="Statistik anzeigen">
                  <i class="ni ni-chart-pie-35 text-red"></i>
                </base-button>

                <language-menu @change="changeLanguage"/>
              </div>
            </div>
            <template>
              <form id="edit-form" @submit="submit" @change="formChanged" ref="form">
                <h6 class="heading-small text-muted mb-4">Allgemein</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-checkbox class="mb-3" v-model="form.visible">
                        Freigeschaltet
                      </base-checkbox>
                    </div>
                    <div class="col-lg-6" v-if="form.visible">
                      <base-checkbox class="mb-3" checked disabled>
                        Darf buchen
                      </base-checkbox>
                      <div class="text-muted invalid-feedback mt--2 mb-3 d-block">Freigeschaltete Hotels dürfen immer
                        buchen.
                      </div>
                    </div>
                    <div class="col-lg-6" v-else>
                      <base-checkbox class="mb-3" v-model="form.allowed_booking">
                        Darf buchen
                      </base-checkbox>
                    </div>
                  </div>

                  <div class="row">
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Name"
                                  placeholder="Name"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.name.$model"
                                  :valid="showErrors ? !$v.form.name.$error : null"
                                  :needs-translation="cleanForm.translationStatus.fieldsToTranslate.includes('name')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.name.required">Bitte geben Sie einen Namen an.
                      </div>
                    </div>
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Interne ID"
                                  placeholder="Interne ID"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.internal_id.$model"
                                  :valid="showErrors ? !$v.form.internal_id.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('internal_id')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.internal_id.required">Bitte geben Sie eine Interne ID an.
                      </div>
                    </div>
                  </div>

                  <div class="row">
<!--                      <div class="col-lg-6">-->
<!--                          <base-input label="Firmenbezeichnung inkl. Reschtsform"-->
<!--                                      placeholder="Firmenbezeichnung inkl. Reschtsform"-->
<!--                                      input-classes="form-control-alternative"-->
<!--                                      v-model="$v.form.company_name.$model"-->
<!--                                      :valid="showErrors ? !$v.form.company_name.$error : null"-->
<!--                          />-->
<!--                          <div class="text-danger invalid-feedback mt&#45;&#45;2 mb-3 d-block" v-if="showErrors && !$v.form.company_name.required">Bitte geben Sie eine Firmenbezeichnung inkl. Reschtsform an.</div>-->
<!--                      </div>-->
                      <div class="col-lg-12">
                          <base-input label="Anzahl Mitarbeiter"
                                      placeholder="Anzahl Mitarbeiter"
                                      input-classes="form-control-alternative"
                                      type="number"
                                      v-model.number="$v.form.employee_count.$model"
                                      :valid="showErrors ? !$v.form.employee_count.$error : null"
                          />
                          <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.employee_count.required">Bitte geben Sie eine Anzahl an Mitarbeitern an.</div>
                          <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.employee_count.decimal">Bitte geben Sie eine Zahl an.</div>
                      </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="form-control-label">
                            Beschreibung
                            <span v-if="cleanForm.translationStatus.needsTranslation('description')"
                                  class="text-warning text-xs">(noch nicht übersetzt)</span>
                          </label>

                          <div class="d-flex align-items-center">
                            <span class="small mr-2">Rich Text Editor</span>
                            <label class="custom-toggle m-0"><input type="checkbox"
                                                                    v-model="richTextEditors.description"><span
                                class="custom-toggle-slider rounded-circle"></span></label>
                          </div>
                        </div>

                        <quill-editor v-if="richTextEditors.description" v-model="$v.form.description.$model"/>
                        <textarea v-else class="form-control" v-model="$v.form.description.$model"></textarea>
                      </div>
<!--                      <div class="text-danger invalid-feedback mt&#45;&#45;2 mb-3 d-block"-->
<!--                           v-if="showErrors && !$v.form.description.required">Bitte geben Sie eine Beschreibung an.-->
<!--                      </div>-->
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="form-control-label">
                            Zusätzliche Informationen
                            <span v-if="cleanForm.translationStatus.needsTranslation('info')"
                                  class="text-warning text-xs">(noch nicht übersetzt)</span>
                          </label>

                          <div class="d-flex align-items-center">
                            <span class="small mr-2">Rich Text Editor</span>
                            <label class="custom-toggle m-0"><input type="checkbox" v-model="richTextEditors.info"><span
                                class="custom-toggle-slider rounded-circle"></span></label>
                          </div>
                        </div>

                        <quill-editor v-if="richTextEditors.info" v-model="form.info"/>
                        <textarea v-else class="form-control" v-model="form.info"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group has-label">
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="form-control-label">
                            Buchungshinweis
                            <span v-if="cleanForm.translationStatus.needsTranslation('booking_info')"
                                  class="text-warning text-xs">(noch nicht übersetzt)</span>
                          </label>

                          <div class="d-flex align-items-center">
                            <span class="small mr-2">Rich Text Editor</span>
                            <label class="custom-toggle m-0"><input type="checkbox"
                                                                    v-model="richTextEditors.booking_info"><span
                                class="custom-toggle-slider rounded-circle"></span></label>
                          </div>
                        </div>

                        <quill-editor v-if="richTextEditors.booking_info" v-model="form.booking_info"/>
                        <textarea v-else class="form-control" v-model="form.booking_info"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Telefon"
                                  placeholder="Telefonnummer"
                                  type="tel"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.telephone.$model"
                                  :valid="showErrors ? !$v.form.telephone.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('telephone')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.telephone.required">Bitte geben Sie eine Telefonnummer an.
                      </div>
                    </div>
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Fax"
                                  placeholder="Fax"
                                  type="tel"
                                  input-classes="form-control-alternative"
                                  v-model="$v.form.fax.$model"
                                  :valid="showErrors ? !$v.form.fax.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('fax')"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="E-Mail für Buchungsanfragen"
                                  placeholder="E-Mail für Buchungsanfragen"
                                  type="email"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.email.$model"
                                  :valid="showErrors ? !$v.form.email.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('email')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.email.required">Bitte geben Sie eine E-Mail-Adresse an.
                      </div>
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-else-if="showErrors && !$v.form.email.email">Die angegebene E-Mail-Adresse ist ungültig.
                      </div>
                    </div>
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Öffentliche E-Mail-Adresse"
                                  placeholder="Öffentliche E-Mail-Adresse"
                                  type="email"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.public_email.$model"
                                  :valid="showErrors ? !$v.form.public_email.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('public_email')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.public_email.required">Bitte geben Sie eine E-Mail-Adresse an.
                      </div>
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-else-if="showErrors && !$v.form.public_email.email">Die angegebene E-Mail-Adresse ist
                        ungültig.
                      </div>
                    </div>
                  </div>

                  <div class="row">
                      <div class="col-lg-6">
                          <base-input label="Anzahl Hotelzimmer"
                                      placeholder="Anzahl Hotelzimmer"
                                      input-classes="form-control-alternative"
                                      type="number"
                                      :required="true"
                                      v-model.number="$v.form.total_room_count.$model"
                                      :valid="showErrors ? !$v.form.total_room_count.$error : null"
                          />
                          <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.total_room_count.required">Bitte geben Sie eine Anzahl an Hotelzimmern an.</div>
                          <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.total_room_count.decimal">Bitte geben Sie eine Zahl an.</div>
                      </div>
<!--                      <div class="col-lg-6">-->
<!--                          <base-input label="Anzahl Einzelzimmer"-->
<!--                                      placeholder="Anzahl Einzelzimmer"-->
<!--                                      input-classes="form-control-alternative"-->
<!--                                      type="number"-->
<!--                                      :required="true"-->
<!--                                      v-model.number="$v.form.single_room_count.$model"-->
<!--                                      :valid="showErrors ? !$v.form.single_room_count.$error : null"-->
<!--                          />-->
<!--                          <div class="text-danger invalid-feedback mt&#45;&#45;2 mb-3 d-block" v-if="showErrors && !$v.form.single_room_count.required">Bitte geben Sie eine Anzahl an Einzelzimmern an.</div>-->
<!--                          <div class="text-danger invalid-feedback mt&#45;&#45;2 mb-3 d-block" v-if="showErrors && !$v.form.single_room_count.decimal">Bitte geben Sie eine Zahl an.</div>-->
<!--                      </div>-->
                  </div>

                  <div class="row">
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="BCC E-Mail für Buchungsanfragen (Komma-getrennt)"
                                  placeholder="BCC E-Mail für Buchungsanfragen (Komma-getrennt)"
                                  type="text"
                                  input-classes="form-control-alternative"
                                  v-model="$v.form.other_emails.$model"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('other_emails')"
                      />
                    </div>
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="URL"
                                  placeholder="URL"
                                  type="url"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.url.$model"
                                  :valid="showErrors ? !$v.form.url.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('url')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.url.required">Bitte geben Sie eine URL an.
                      </div>
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-else-if="showErrors && !$v.form.url.url">Die angegebene URL ist ungültig.
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group has-label">
                        <label class="form-control-label d-block">Währung *</label>

                        <base-dropdown tag="div">
                          <base-button slot="title" type="form-control-alternative" class="dropdown-toggle">
                            {{!(form.currency === "0" || form.currency === null) ? form.currency : 'Nicht ausgewählt'}}
                          </base-button>
                          <a class="dropdown-item" @click="form.currency = null">Auswahl zurücksetzen</a>
                          <a class="dropdown-item" @click="form.currency = 'EUR'">EUR</a>
                          <a class="dropdown-item" @click="form.currency = 'CHF'">CHF</a>
                        </base-dropdown>
                      </div>
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && form.currency === null || form.currency === '0' ">Bitte wählen Sie eine Währung aus.
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="my-4"/>

                <!-- <h6 class="heading-small text-muted mb-4">Ansprechpartner</h6>
                <div class="pl-lg-4">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group has-label">
                                <label class="form-control-label d-block">Anrede *</label>

                                <base-dropdown tag="div">
                                    <base-button slot="title" type="form-control-alternative" :block="true" class="dropdown-toggle">
                                        {{ getSalutationText(form.contact_salutation) }}
                                    </base-button>
                                    <a class="dropdown-item text-normal" @click="form.contact_salutation = 'MRS'">Frau</a>
                                    <a class="dropdown-item text-normal" @click="form.contact_salutation = 'MR'">Herr</a>
                                    <a class="dropdown-item text-normal" @click="form.contact_salutation = 'DIVERSE'">Divers</a>
                                </base-dropdown>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <base-input label="Titel"
                                        placeholder="Titel"
                                        input-classes="form-control-alternative"
                                        v-model="form.contact_title"
                                        :needs-translation="cleanForm.translationStatus.needsTranslation('contact_title')"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <base-input label="Vorname"
                                        placeholder="Vorname"
                                        input-classes="form-control-alternative"
                                        :required="true"
                                        v-model="$v.form.contact_firstname.$model"
                                        :valid="showErrors ? !$v.form.contact_firstname.$error : null"
                            />
                            <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.contact_firstname.required">Bitte geben Sie einen Vornamen an.</div>
                        </div>
                        <div class="col-lg-6">
                            <base-input label="Nachname"
                                        placeholder="Nachname"
                                        input-classes="form-control-alternative"
                                        :required="true"
                                        v-model="$v.form.contact_lastname.$model"
                                        :valid="showErrors ? !$v.form.contact_lastname.$error : null"
                            />
                            <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.contact_lastname.required">Bitte geben Sie einen Nachnamen an.</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <base-input label="Position"
                                        placeholder="Position"
                                        input-classes="form-control-alternative"
                                        :required="true"
                                        v-model="$v.form.contact_position.$model"
                                        :valid="showErrors ? !$v.form.contact_position.$error : null"
                                        :needs-translation="cleanForm.translationStatus.needsTranslation('contact_position')"
                            />
                            <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.contact_position.required">Bitte geben Sie einen Position an.</div>
                        </div>
                    </div>
                </div> -->

                <!-- <hr class="my-4" /> -->

                <h6 class="heading-small text-muted mb-4">Adresse</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Straße"
                                  placeholder="Straße"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.address.$model"
                                  :valid="showErrors ? !$v.form.address.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('address')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.address.required">Bitte geben Sie eine Straße an.
                      </div>
                    </div>
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Postleitzahl"
                                  placeholder="Postleitzahl"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.postalcode.$model"
                                  :valid="showErrors ? !$v.form.postalcode.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('postalcode')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.postalcode.required">Bitte geben Sie eine Postleitzahl an.
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div :class="[showEdits ? 'col-lg-12' : 'col-lg-6']">
                      <base-input label="Stadt"
                                  placeholder="Stadt"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.city.$model"
                                  :valid="showErrors ? !$v.form.city.$error : null"
                                  :needs-translation="cleanForm.translationStatus.needsTranslation('city')"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.city.required">Bitte geben Sie eine Stadt an.
                      </div>
                    </div>
                    <div :class="[showEdits ? 'col-lg-6' : 'col-lg-3']">
                      <div class="form-group has-label">
                        <label class="form-control-label d-block">Land *</label>

                        <base-dropdown tag="div">
                          <base-button slot="title" type="form-control-alternative" :block="true"
                                       class="dropdown-toggle">
                            {{ selectedCountry.name }}
                          </base-button>
                          <a class="dropdown-item text-normal" v-for="country in selectableCountries" :key="country.id"
                             @click="form.country = country.id">{{ country.name }}</a>
                        </base-dropdown>
                      </div>
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && form.country === null">Bitte wählen Sie ein Land aus.
                      </div>
                    </div>
                    <div :class="[showEdits ? 'col-lg-6' : 'col-lg-3']">
                      <div class="form-group has-label">
                        <label class="form-control-label d-block">Bundesland</label>

                        <base-dropdown tag="div">
                          <base-button slot="title" type="form-control-alternative" :block="true"
                                       class="dropdown-toggle">
                            {{ selectedState.name }}
                          </base-button>
                          <a class="dropdown-item text-normal" @click="form.state = null">Auswahl zurücksetzen</a>
                          <a class="dropdown-item text-normal" v-for="state in selectableStates" :key="state.id"
                             @click="form.state = state.id">{{ state.name }}</a>
                        </base-dropdown>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div :class="[showEdits ? 'col-lg-6' : 'col-lg-5']">
                      <base-input label="Latitude"
                                  placeholder="Latitude"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.latitude.$model"
                                  :valid="showErrors ? !$v.form.latitude.$error : null"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.latitude.required">Bitte geben Sie eine Latitude an.
                      </div>
                    </div>
                    <div :class="[showEdits ? 'col-lg-6' : 'col-lg-5']">
                      <base-input label="Longitude"
                                  placeholder="Longitude"
                                  input-classes="form-control-alternative"
                                  :required="true"
                                  v-model="$v.form.longitude.$model"
                                  :valid="showErrors ? !$v.form.longitude.$error : null"
                      />
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && !$v.form.longitude.required">Bitte geben Sie eine Longitude an.
                      </div>
                    </div>
                    <div c:class="[showEdits ? 'col-lg-12' : 'col-lg-2']">
                      <label class="form-control-label">Automatisch ausfüllen</label>
                      <base-button type="neutral" icon="fas fa-sync" :block="true" @click="getLatLong"/>
                    </div>
                  </div>
                </div>

                <hr class="my-4"/>

                <h6 class="heading-small text-muted mb-4">Medien</h6>
                <div class="pl-lg-4 position-relative" v-if="!isNew">
                  <transition-group name="media-list" tag="div">
                    <div v-for="(image, index) in media" :key="image.id"
                         class="media-container row no-gutters align-items-center">
                      <div class="col-2 px-2 position-relative">
                        <img :class="['preview-image', {'hidden': !image.dataSet.visible}]" :src="image.src"
                             @click="openLightbox(image.src)">

                        <div class="uploading-spinner" v-if="!image.uploaded">
                          <i class="fas fa-circle-notch fa-2x fa-spin"></i>
                        </div>
                      </div>
                      <div class="col-8 px-2">
                        <span>{{ image.name }}</span>
                        <span class="ml-2 text-green" v-if="isGalleryPicture(index)">(Galeriebild)</span>
                        <span class="ml-2 text-orange" v-if="!image.dataSet.visible">(Nicht sichtbar)</span>
                      </div>
                      <div class="col-2 px-2 d-flex justify-content-end">
                        <base-button size="sm" type="neutral"
                                     :icon="image.dataSet.visible ? 'far fa-eye' : 'far fa-eye-slash'"
                                     @click="changeImageVisibility(index)" title="Sichtbarkeit ändern"/>
                        <div class="btn-group mr-2" role="group" aria-label="Sortierung">
                          <base-button size="sm" type="neutral" icon="fas fa-arrow-up"
                                       @click="changeImageSorting(index, -1)" :disabled="index === 0"
                                       title="Nach vorne sortieren"/>
                          <base-button size="sm" type="neutral" icon="fas fa-arrow-down"
                                       @click="changeImageSorting(index, 1)" :disabled="index === media.length - 1"
                                       title="Nach hinten sortieren"/>
                        </div>
                        <base-button size="sm" type="danger" icon="fas fa-trash" @click="deleteFile(image)"
                                     title="Löschen"/>
                      </div>
                    </div>
                  </transition-group>
                  <div class="upload-images pt-3">
                    <base-button type="neutral" icon="ni ni-cloud-upload-96" @click="selectFiles">Bilder hinzufügen
                    </base-button>
                    <input ref="files" type="file" multiple accept=".png,.jpg,.jpeg,.gif" @change="handleFileSelection">
                  </div>
                  <div class="media-spinner" v-if="spinners.media">
                    <i class="fas fa-circle-notch fa-3x fa-spin"></i>
                  </div>
                </div>
                <div class="pl-lg-4" v-else>
                  <span>Bitte speichern Sie erst das neu angelegte Hotel, bevor Sie Bilder hochladen können.</span>
                </div>

                <hr class="my-4"/>

                <h6 class="heading-small text-muted mb-4">Hotelausstattung</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label d-block">Sterne *</label>

                        <base-dropdown tag="div">
                          <base-button slot="title" type="form-control-alternative" class="dropdown-toggle">
                            {{ getStarsText(form.stars) }}
                          </base-button>
                          <a class="dropdown-item" @click="form.stars = null">Auswahl zurücksetzen</a>
                          <a class="dropdown-item" @click="form.stars = '0'">Nicht kategorisiert</a>
                          <a class="dropdown-item" @click="form.stars = '1'">1 Stern</a>
                          <a class="dropdown-item" @click="form.stars = '2'">2 Sterne</a>
                          <a class="dropdown-item" @click="form.stars = '3'">3 Sterne</a>
                          <a class="dropdown-item" @click="form.stars = '3+'">3 Sterne Superior</a>
                          <a class="dropdown-item" @click="form.stars = '4'">4 Sterne</a>
                          <a class="dropdown-item" @click="form.stars = '4+'">4 Sterne Superior</a>
                          <a class="dropdown-item" @click="form.stars = '5'">5 Sterne</a>
                          <a class="dropdown-item" @click="form.stars = '5+'">5 Sterne Superior</a>
                        </base-dropdown>
                      </div>
                      <div class="text-danger invalid-feedback mt--2 mb-3 d-block"
                           v-if="showErrors && form.stars === null">Bitte wählen Sie eine Sternebewertung aus.
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div :class="[showEdits ? 'col-6' : 'col-md-6 col-lg-3']" v-for="equipment in hotelEquipments" :key="equipment.field">
                      <base-checkbox class="mb-3" :checked="form.hotel_equipment.includes(equipment.field)"
                                     @input="changeCheckboxArray($event, 'hotel_equipment', equipment)">
                        {{ equipment.title }}
                      </base-checkbox>
                    </div>
                  </div>
                </div>

                <hr class="my-4"/>

                <h6 class="heading-small text-muted mb-4">Zimmerausstattung</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div :class="[showEdits ? 'col-6' : 'col-md-6 col-lg-3']" v-for="equipment in roomEquipments" :key="equipment.field">
                      <base-checkbox class="mb-3" :checked="form.room_equipment.includes(equipment.field)"
                                     @input="changeCheckboxArray($event, 'room_equipment', equipment)">
                        {{ equipment.title }}
                      </base-checkbox>
                    </div>
                  </div>
                </div>

                <hr class="my-4"/>

                <h6 class="heading-small text-muted mb-4">Hotel-Spezifikationen</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div :class="[showEdits ? 'col-6' : 'col-md-6 col-lg-3']" v-for="specification in hotelSpecifications"
                         :key="specification.field">
                      <base-checkbox class="mb-3" :checked="form.hotel_specification.includes(specification.field)"
                                     @input="changeCheckboxArray($event, 'hotel_specification', specification)">
                        {{ specification.title }}
                      </base-checkbox>
                    </div>
                  </div>
                </div>
              </form>
            </template>
            <template slot="footer" v-if="!showEdits">
              <base-button type="primary" form="edit-form" nativeType="submit"
                           :icon="spinners.submitButton ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'">
                Speichern
              </base-button>

              <base-button type="primary" form="edit-form"
                           :icon="spinners.submitAndClose ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'"
                           @click="submitAndClose">
                Speichern &amp; schließen
              </base-button>

              <base-button type="link" form="edit-form" icon="ni ni-fat-remove" @click="close">Schließen</base-button>
              <span id="pvcrm_id">PVCRM-ID: {{hotel['selene_id']}}</span>
            </template>
          </card>
        </div>
      </div>

      <modal :show.sync="languageChanged"
             gradient="primary"
             modal-classes="modal-danger modal-dialog-centered">
        <div class="py-3 text-center">
          <i class="fas fa-save fa-3x"></i>
          <h4 class="heading mt-4">Eintrag speichern bevor die Sprache gewechselt wird?</h4>
          <p class="mb-0">Beim wechseln der Sprache gehen alle nicht gespeicherten Änderungen verloren.</p>
        </div>
        <template slot="footer">
          <base-button type="white" @click="saveAndReload">
            <i v-if="spinners.modalButton" class="fas fa-circle-notch fa-spin"></i>
            Speichern
          </base-button>
          <base-button type="link"
                       text-color="white"
                       class="ml-auto"
                       @click="reload">
            Nicht speichern
          </base-button>
        </template>
      </modal>

      <div id="photo-lightbox" v-if="lightbox.show">
        <img class="lightbox-image" :src="lightbox.source">

        <button class="lightbox-close" @click="lightbox.show = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div :class="{'split': showEdits}" v-if="showEdits">
        <ShowHotelEdits :hotel="stagingHotels[0]" :hotelOldData="hotel" @closeEdit="closeEdit" @reload="reload">
        </ShowHotelEdits>
      </div>
    </div>

  </div>
</template>
<script>
import {decimal, email, required, url} from 'vuelidate/lib/validators'
import apiService from '@/apiService'
import Localized from '@/Localized'
import UploadFile from '@/UploadFile'
import helper from '@/helper'
import LanguageMenu from '@/views/Dashboard/LanguageMenu.vue'
import eventBus from '@/eventBus'
import ShowHotelEdits from './ShowHotelEdits.vue'

export default {

  mounted() {
    this.checkForStagingHotel()
  },
  name: 'edit-hotel',
  components: {
    ShowHotelEdits,
    LanguageMenu,
  },
  props: {
    hotel: {
      type: Object,
      default() {
        return new Localized({
          id: null,
          name: '',
          internal_id: '',
          description: '',
          info: '',
          booking_info: '',
          telephone: '',
          fax: '',
          email: '',
          public_email: '',
          other_emails: '',
          url: '',
          currency: null,
          address: '',
          postalcode: '',
          city: '',
          country: null,
          state: null,
          latitude: 0,
          longitude: 0,
          stars: null,
          company_name: '',
          contact_salutation: 'MRS',
          contact_title: '',
          contact_firstname: '',
          contact_lastname: '',
          contact_position: '',
          total_room_count: 0,
          single_room_count: 0,
          employee_count: 0,
          hotel_equipment: [],
          room_equipment: [],
          hotel_specification: [],
        });
      },
    },
    closeParams: {
      type: Object,
      default() {
        return {
          name: 'hotels',
        };
      },
    },
  },
  data() {
    return {
      showEdits: false,
      stagingHotels: null,
      saveEventId: null,
      closeEventId: null,
      showErrors: false,
      richTextEditors: {
        description: true,
        info: true,
        booking_info: true,
      },
      spinners: {
        submitButton: false,
        submitAndClose: false,
        modalButton: false,
        media: true,
      },
      lightbox: {
        show: false,
        source: '',
      },
      languageChanged: false,
      previousLanguage: null,
      cleanForm: {...this.hotel},
      form: this.hotel,
      countries: [],
      states: [],
      media: [],
      hotelEquipments: [
        {field: 'elevator', title: 'Aufzug'},
        {field: 'breakfast_service', title: 'Frühstück-Service'},
        {field: 'bar_or_cafe', title: 'Bar / Café'},
        {field: 'restaurant', title: 'Restaurant'},
        {field: 'meeting_rooms', title: 'Tagungsräume'},
        {field: 'pets_allowed', title: 'Haustiere erlaubt'},
        {field: 'hotel_parking', title: 'hoteleigene Parkplätze'},
        {field: 'free_parking', title: 'Kostenlose Parkplätze'},
        {field: 'underground_parking', title: 'Tief- / Garagenstellplätze'},
        {field: 'indoor_pool', title: 'Innen-Pool'},
        {field: 'outdoor_pool', title: 'Außen-Pool'},
        {field: 'sauna', title: 'Sauna'},
        {field: 'fitness_room', title: 'Fitnessraum'},
        {field: 'massages', title: 'Massagen'},
        {field: 'beauty', title: 'Beauty-Anwendungen'},
        {field: 'bike_rental', title: 'Fahrradverleih'},
        {field: 'tennis', title: 'Tennis'},
        {field: 'golf', title: 'Golf'},
        {field: 'e_vehicle_charging', title: 'E-Fahrzeug Ladestation'},
        {field: 'barrier_free', title: 'barrierefreies Hotel'},
        {field: 'free_wifi', title: 'kostenloses WLAN'},
      ],
      roomEquipments: [
        {field: 'bathrobe', title: 'Bademantel'},
        {field: 'bathtub', title: 'Badewanne'},
        {field: 'shower', title: 'Dusche'},
        {field: 'hairdryer', title: 'Haartrockner'},
        {field: 'aircondition', title: 'Klimaanlage'},
        {field: 'tv', title: 'TV'},
        {field: 'desk', title: 'Schreibtisch'},
        {field: 'telephone', title: 'Telefon'},
        {field: 'room_safe', title: 'Zimmersafe'},
        {field: 'minibar', title: 'Minibar'},
        {field: 'coffee_machine', title: 'Kaffeemaschine'},
        {field: 'room_service', title: 'Zimmerservice'},
      ],
      hotelSpecifications: [
        {field: 'wellness', title: 'Wellness & Erholung'},
        {field: 'quiet', title: 'Ruhig & abgelegen'},
        {field: 'nature', title: 'Naturnahe relaxen'},
        {field: 'individual', title: 'Individualhotel'},
        {field: 'chain_hotel', title: 'Kettenhotel'},
        {field: 'dog_friendly', title: 'Urlaub mit Hund'},
        {field: 'meetings', title: 'Tagungen & Seminare'},
        {field: 'traffic_friendly', title: 'Verkehrsgüntig'},
        {field: 'sporty', title: 'Sportlich aktiv +'},
        {field: 'golf', title: 'Golfhotel'},
        {field: 'tennis', title: 'Tennishotel'},
        {field: 'mountains', title: 'Winterurlaub & Berge'},
        {field: 'city', title: 'Stadttourismus (zentral)'},
        {field: 'barrier_free', title: 'komplett barrierefrei'},
        {field: 'family_friendly', title: 'familienfreundlich'},
        {field: 'pension', title: 'Pension / Gasthof'},
        {field: 'beach', title: 'Strand- und Badehotel'},
      ],
    };
  },
  computed: {
    isNew() {
      return this.form.id === null;
    },
    selectableCountries() {
      if (typeof this.selectedState.id === 'undefined') return this.countries;

      return this.countries.filter(country => {
        return this.selectedState.country === country.id;
      });
    },
    selectableStates() {
      if (typeof this.selectedCountry.id === 'undefined') return this.states;

      return this.states.filter(state => {
        return this.form.country === state.country;
      });
    },
    selectedCountry() {
      return this.countries.find(country => country.id === this.form.country) || {name: 'Kein Land ausgewählt'};
    },
    selectedState() {
      return this.states.find(state => state.id === this.form.state) || {name: 'Kein Bundesland ausgewählt'};
    },
  },
  watch: {
    form() {
      this.formChanged();
    },
    cleanForm() {
      this.formChanged();
    },
  },
  validations: {
    form: {
      name: {required},
      internal_id: {required},
      description: {},
      telephone: {required},
      fax: {},
      email: {required, email},
      public_email: {required, email},
      other_emails: {},
      url: {required, url},
      // contact_firstname: { required },
      // contact_lastname: { required },
      // contact_position: { required },
      total_room_count: { required, decimal },
      single_room_count: { required, decimal },
      employee_count: { required, decimal },
      // company_name: { required },
      address: {required},
      postalcode: {required},
      city: {required},
      currency:{required, isNonZero(value) {
          value === "0" || value === null ? this.showErrorForCurrency() : true
          return value !== "0" && value !== null
        }},
      latitude: {required, decimal, isNonZero(value) {
          return value !== 0 && value !== 0.0;
        }},
      longitude: {required, decimal, isNonZero(value) {
          return value !== 0 && value !== 0.0;
        }},
    },
  },
  methods: {
    showErrorForCurrency() {
      this.$notify({
        type: 'danger',
        title: 'Bitte geben sie eine Währung an.'
      });
    },
    closeEdit() {
      this.showEdits = false
    },
    async getLatLong() {
      const latLong = await apiService.getLatLong(this.form.address, this.form.postalcode, this.form.country);

      if (latLong === null) {
        this.$notify({
          type: 'danger',
          title: 'Die Adresse konnte nicht zu Latitude und Longitude umgewandelt werden!'
        });
      } else {
        this.form.latitude = latLong.lat;
        this.form.longitude = latLong.long;

        this.$notify({
          type: 'success',
          title: 'Latitude und Longitude aktualisiert!'
        });
      }
    },
    async submit(event = null, language = null) {
      if (event) event.preventDefault();
      console.log(this.form.currency)

      this.spinners.submitButton = this.spinners.submitAndClose ? false : true;

      this.$v.$touch();

      const country = this.countries.find(country => country.id === this.form.country);
      const state = this.states.find(state => state.id === this.form.state);

      if (!state) this.form.state = null;

      if (this.$v.$invalid || !country || this.form.stars === null || this.form.currency === null) {
        this.showErrors = true;
      } else {
        let responseData;
        this.form.staging_finished = true

        if(!this.hotel['selene_id'] || this.hotel['selene_id'].length < 10){
          this.form.selene_id = 0
        }else{
          this.form.selene_id = this.hotel['selene_id']
        }

        console.log(this.form)

        console.log(this.hotel)

        if (this.isNew) {
          responseData = await apiService.createEntry('hotel', this.form, language);
        } else {
          responseData = await apiService.updateEntry('hotel', this.form.id, this.form, language);
        }

        if (responseData) {
          this.showErrors = false;
          this.form = responseData;
          this.cleanForm = {...responseData};

          this.$notify({
            type: 'success',
            title: 'Daten erfolgreich gespeichert!'
          });
        }
      }

      this.spinners.submitButton = false;
    },
    formChanged() {
      eventBus.set('unsaveToClose', !helper.deepEqualObjects(this.cleanForm, this.form));
    },
    close() {
      this.$router.push(this.closeParams);
    },
    async saveAndClose() {
      await this.submit();

      return !this.showErrors;
    },
    async submitAndClose() {
      this.spinners.submitAndClose = true;

      await this.submit();

      this.spinners.submitAndClose = false;

      if (!this.showErrors) this.close();
    },
    changeLanguage(oldLanguage) {
      this.previousLanguage = oldLanguage;

      if (!helper.deepEqualObjects(this.cleanForm, this.form)) {
        this.languageChanged = true;
      } else {
        this.reload();
      }
    },
    async saveAndReload() {
      this.spinners.modalButton = true;

      await this.submit(null, this.previousLanguage);
      await this.reload();

      this.languageChanged = false;
      this.spinners.modalButton = false;
    },
    async reload() {
      if (this.isNew) {
        this.form = this.hotel;
        this.cleanForm = {...this.hotel};
      } else {
        const responseData = await apiService.loadEntry('hotel', this.form.id);

        if (responseData) {
          this.showErrors = false;
          this.form = responseData;
          this.cleanForm = {...responseData};
        }
      }

      this.languageChanged = false;
    },
    async loadCountriesAndStates() {
      let [countriesResponse, statesResponse] = await Promise.all([
        apiService.loadTableData('country', {}, 0),
        apiService.loadTableData('state', {}, 0)
      ]);

      if (countriesResponse !== null) {
        this.countries = countriesResponse.data;
      }
      if (statesResponse !== null) {
        this.states = statesResponse.data;
      }
    },
    async loadImages() {
      this.spinners.media = true;

      if (this.isNew) {
        this.spinners.media = false;

        return;
      }

      const response = await apiService.loadTableData('picture', {
        reference_table: 'hotel',
        reference_id: this.form.id,
      });

      if (response !== null) {
        this.media = response.data.map(image => new UploadFile(image))
            .sort((a, b) => a.dataSet.sorting - b.dataSet.sorting);
      }

      this.spinners.media = false;
    },
    selectFiles() {
      this.$refs.files.value = null;

      this.$refs.files.click();
    },
    handleFileSelection() {
      const newFiles = this.$refs.files.files;

      for (let newFile of newFiles) {
        this.media = [
          ...this.media,
          new UploadFile(newFile, {
            reference_table: 'hotel',
            reference_id: this.form.id,
            sorting: this.media.length,
          }),
        ];
      }
    },
    async deleteFile(image) {
      this.spinners.media = true;

      if (image.uploaded) {
        await apiService.deleteEntry('picture', image.dataSet.id);
      }

      this.media = this.media.filter(file => file.id !== image.id);
      this.media.forEach((file, index) => file.dataSet.sorting = index);

      await Promise.all(this.media.map(file => {
        return apiService.updateEntry('picture', file.dataSet.id, file.dataSet);
      }));

      this.spinners.media = false;
    },
    async changeImageSorting(index, direction) {
      this.spinners.media = true;

      const newIndex = index + direction;

      if (newIndex < 0 || newIndex >= this.media.length) {
        this.spinners.media = false;

        return;
      }

      this.media[index].dataSet.sorting = newIndex;
      this.media[newIndex].dataSet.sorting = index;

      let [indexResponse, newIndexResponse] = await Promise.all([
        apiService.updateEntry('picture', this.media[index].dataSet.id, this.media[index].dataSet),
        apiService.updateEntry('picture', this.media[newIndex].dataSet.id, this.media[newIndex].dataSet)
      ]);

      if (indexResponse !== null) {
        this.media[index].loadDataFromLocalized(indexResponse);
      }
      if (newIndexResponse !== null) {
        this.media[newIndex].loadDataFromLocalized(newIndexResponse);
      }

      this.media = this.media.sort((a, b) => a.dataSet.sorting - b.dataSet.sorting);

      this.spinners.media = false;
    },
    async changeImageVisibility(index) {
      this.spinners.media = true;

      const dataSet = {...this.media[index].dataSet};

      dataSet.visible = !dataSet.visible;

      const response = await apiService.updateEntry('picture', dataSet.id, dataSet);

      if (response !== null) {
        this.media[index].loadDataFromLocalized(response);
      }

      this.spinners.media = false;
    },
    isGalleryPicture(index) {
      return this.media.findIndex(picture => picture.dataSet.visible) === index;
    },
    changeCheckboxArray(checked, name, data) {
      const current = this.form[name];

      if (checked) {
        this.$set(this.form, name, [...new Set([...current, data.field])]);
      } else {
        this.$set(this.form, name, current.filter(field => field !== data.field));
      }
    },
    getStarsText(stars) {
      switch (stars) {
        case null:
          return 'Nicht ausgewählt';
        case '0':
          return 'Nicht kategorisiert';
        case '1':
        case '1 Stern':
          return '1 Stern';
        case '2':
        case '2 Sterne':
          return '2 Sterne';
        case '3':
        case '3 Sterne':
          return '3 Sterne';
        case '3+':
        case '3 Sterne Superior':
          return '3 Sterne Superior';
        case '4':
        case '4 Sterne':
          return '4 Sterne';
        case '4+':
        case '4 Sterne Superior':
          return '4 Sterne Superior';
        case '5':
        case '5 Sterne':
          return '5 Sterne';
        case '5+':
        case '5 Sterne Superior':
          return '5 Sterne Superior';
      }
    },
    getSalutationText(salutation) {
      switch (salutation) {
        case 'MR':
          return 'Herr';
        case 'MRS':
          return 'Herr';
        case 'DIVERSE':
          return 'Divers';
      }
    },
    openLightbox(source) {
      this.lightbox = {
        show: true,
        source,
      };
    },
    showBookings() {
      this.$router.push({
        name: 'bookings',
        params: {
          search: {
            hotel__name: this.cleanForm.name,
          },
          closeParams: {
            name: 'hotels',
            params: {
              pagination: this.pagination,
              search: this.search,
              sorting: this.sorting,
            },
          },
        },
      });
    },
    async checkForStagingHotel() {
      let response = [];
      if (this.stagingHotels) {
        this.showEdits = !this.showEdits
      } else {
        if (this.hotel.selene_id !== "" && this.hotel.selene_id.length > 10) {
          response = await apiService.getStagingHotel(this.hotel['selene_id'])
        }
        if (response.data && response !== [] && response.data.length > 0) {
          this.stagingHotels = response.data
          this.showEdits = !this.showEdits
        }
      }
    },

    async showStatistics() {
      const response = await apiService.getStatisticsForOne('hotel', this.form.id);

      await this.$router.push({
        name: 'hotel-statistics', params: {
          statistics: response,
          closeParams: {
            name: 'statistics',
          },
        }
      });
    },
  },
  created() {
    this.loadCountriesAndStates();
    this.loadImages();

    this.saveEventId = eventBus.on('save-and-close', async () => {
      const success = await this.saveAndClose();

      eventBus.emit('entry-saved', success);
    });

    this.closeEventId = eventBus.on('prepare-to-close', () => {
      this.formChanged();
    });
  },
  beforeDestroy() {
    eventBus.off('save-and-close', this.saveEventId);
    eventBus.off('prepare-to-close', this.closeEventId);
  },
};
</script>
<style scoped>
.media-list-move {
  transition: transform .3s;
}

.media-list-enter-active, .media-list-leave-active {
  transition: all .3s;
}

.media-list-enter, .media-list-leave-to /* .list-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.media-container {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  border-radius: 0.375rem;
  margin-bottom: .5rem;
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.media-container .preview-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: zoom-in;
}

.media-container .preview-image.hidden {
  opacity: .4;
}

.uploading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
}

.media-spinner {
  position: absolute;
  top: 0;
  left: 1.5rem;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
}

.upload-images {
  position: relative;
}

.upload-images input[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#photo-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

#photo-lightbox .lightbox-image {
  max-width: 80vw;
  max-height: 80vh;
}

#photo-lightbox .lightbox-close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  font-size: 2rem;
  color: #fff;
  background: transparent;
  border: none;
  outline: none;
}

textarea.form-control {
  min-height: 150px;
}

.split {
  width: 50%;
}
</style>
