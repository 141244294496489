import apiService from '@/apiService'

export default class UploadFile {

    constructor(file, tableData = {}, upload = true) {
        if(file instanceof File) {
            this.loadDataFromFile(file, tableData);

            if(upload) this.uploadFile();
        } else {
            this.loadDataFromLocalized(file);
        }

        this.id = this.getUniqueId();
    }

    loadDataFromFile(file, tableData) {
        this.file = file;
        this.tableData = tableData;
        this.dataSet = {
            display_name: file.name,
            id: null,
            link: null,
            reference_id: tableData.reference_id,
            reference_table: tableData.reference_table,
            sorting: tableData.sorting,
            thumb: null,
            visible: true,
        };
        this.name = this.file.name;
        this.src = URL.createObjectURL(this.file);
        this.uploaded = false;
    }

    loadDataFromLocalized(localized) {
        this.dataSet = localized;
        this.name = localized.display_name;
        this.src = localized.link;
        this.uploaded = true;
    }

    getUniqueId() {
        const value = `${performance.now()}${this.src}${this.name}`;
        let hash = 0;

        for(let i = 0; i < value.length; i++) {
          hash = ((hash << 5) - hash) + value.charCodeAt(i);
          hash |= 0;
        }

        return hash;
    }

    async uploadFile() {
        const picture = await apiService.uploadPicture(this.file, this.tableData);

        if(picture !== null) {
            this.loadDataFromLocalized(picture);
        }
    }
}