<template>
  <div data-view="email-preview">

    <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <h3>{{template.name}}</h3>
<!--    <quill-editor v-html="template.template"/>-->
    <div class="border m-5" v-html="template.template"></div>
    <base-button v-if="!completeBack" type="primary" size="sm" @click="saveTemplateAndBack()" title="Speichern">
      <i class="ni ni-check-bold"></i>
      Speichern und schließen
    </base-button>
    <base-button type="link" size="sm" @click="back()" title="Speichern">
      zurück
    </base-button>
  </div>
</template>
<script>

import apiService from "@/apiService";

export default {
  name: 'email-preview',
  components: {},
  props: {
    template: Object,
    completeBack: Boolean,
    startPagination: Object,
    startSearch: Object,
    startSorting: Object,
  },
  data() {
    return {
      spinners: {
        table: false,
      },
      deleteEntry: {
        showModal: false,
        title: '',
        id: 0,
      },
      filename: null,
      value: null
    }
  },
  watch: {
    sorting() {
      this.reload();
    },
  },
  methods: {
    back() {
      if (!this.completeBack){
        this.$router.push({name: "edit-email-template", params:{template: this.template}})
      }else{
        this.$router.push({name: "email-templates"})
      }
    },
    saveTemplateAndBack() {
      apiService.setEmailTemplates(this.template.filename, this.template.value)
      this.$router.push({name: "email-templates", params:{success:true, file:this.template.name}})
    },
  }
}
</script>
<style>
</style>