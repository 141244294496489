<template>
    <div data-view="dashboard">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
            <h1 class="display-2 text-white">Willkommen im Personights Adminbereich!</h1>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="card shadow">
                <div class="card-header border-0 pb-0">
                    <h3 class="mb-0">Schnellnavigation</h3>
                </div>

                <div class="card-body pb-0">
                    <div class="row">
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Menüpunkt"
                                        type="gradient-blue"
                                        sub-title="Hotels"
                                        icon="ni ni-building"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Hotels anlegt, bearbeitet und freigeschaltet werden.
                                    <router-link to="/hotels" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Menüpunkt"
                                        type="gradient-purple"
                                        sub-title="Preise"
                                        icon="ni ni-money-coins"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Preise anlegt und bearbeitet werden.
                                    <router-link to="/prices" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Menüpunkt"
                                        type="gradient-orange"
                                        sub-title="Buchungen"
                                        icon="ni ni-tag"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Buchungen anlegt und bearbeitet werden.
                                    <router-link to="/bookings" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Menüpunkt"
                                        type="gradient-green"
                                        sub-title="Benutzer"
                                        icon="ni ni-single-02"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Schnittstellenzugänge und Benutzer für den Adminbereich angelegt und bearbeitet werden.
                                    <router-link to="/users" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Menüpunkt"
                                        type="gradient-red"
                                        sub-title="Statistiken"
                                        icon="ni ni-chart-pie-35"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Statistiken zu den Hotels eingesehen werden.
                                    <router-link to="/statistics" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Menüpunkt"
                                        type="gradient-info"
                                        sub-title="Sprachen & Länder"
                                        icon="ni ni-chart-pie-35"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Sprachen, Länder & Bundesländer angelegt und bearbeitet werden.
                                    <router-link to="/languages-and-countries" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Benutzer"
                                        type="gradient-yellow"
                                        sub-title="Mein Profil"
                                        icon="ni ni-single-02"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Sie Ihr Profil bearbeiten und z.B. das Passwort ändern.
                                    <router-link to="/profile" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Benutzer"
                                        type="gradient-default"
                                        sub-title="Einstellungen"
                                        icon="ni ni-settings-gear-65"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Hier können Sie das Verhalten des Adminbereichs anpassen.
                                    <router-link to="/settings" class="stretched-link" />
                                </template>
                            </stats-card>
                        </div>
                        <div class="col-xl-3 col-lg-6 mb-4">
                            <stats-card title="Benutzer"
                                        type="gradient-indigo"
                                        sub-title="Abmelden"
                                        icon="ni ni-button-power"
                                        class="h-100 shadow-sm--hover"
                            >
                                <template slot="footer">
                                    Meldet Sie vom Adminbereich ab.
                                    <a href="#" class="stretched-link" @click="logoutUser"></a>
                                </template>
                            </stats-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import apiService from '@/apiService'

export default {
    methods: {
        logoutUser() {
            apiService.logout();
        },
    },
};
</script>
<style></style>
