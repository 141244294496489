<template>
    <div data-view="prices">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <prices-table title="Preise" :startPagination="startPagination" :startSearch="startSearch" :startSorting="startSorting"></prices-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PricesTable from './Tables/PricesTable'
import userSettings from '@/userSettings'

export default {
    name: 'hotels',
    components: {
        PricesTable,
    },
    props: {
        pagination: {
            type: Object,
            default() {
                return {
                    showAll: false,
                    total: 0,
                    perPage: userSettings.getSetting('rowsPerTable'),
                    value: 1,
                };
            },
        },
        search: {
            type: Object,
            default() {
                return {
                    currency: null,
                    price_singleroom: '',
                    price_doubleroom: '',
                    valid_from: '',
                    valid_to: '',
                };
            },
        },
        sorting: {
            type: Object,
            default() {
                return {
                    field: 'currency',
                    dir: 'asc',
                };
            },
        },
    },
    data() {
        return {
            startPagination: this.pagination,
            startSearch: this.search,
            startSorting: this.sorting,
        };
    },
};
</script>
<style></style>
