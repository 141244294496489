<template>
    <div data-view="languages-and-countries">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row mb-5">
                <div class="col">
                    <languages-table title="Sprachen"></languages-table>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col">
                    <countries-table title="Länder"></countries-table>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <states-table title="Bundesländer"></states-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LanguagesTable from './Tables/LanguagesTable'
import CountriesTable from './Tables/CountriesTable'
import StatesTable from './Tables/StatesTable'

export default {
    name: 'languages-and-countries',
    components: {
        LanguagesTable,
        CountriesTable,
        StatesTable,
    },
};
</script>
<style></style>
