<template>
    <div class="card shadow" data-view="hotel-statistics-table">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="!pagination.showAll" type="primary" size="sm" @click="showAll">Alle anzeigen</base-button>
                    <base-button type="primary" size="sm" @click="reload">Aktualisieren</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="tableData"
                    :column-count="6"
                    :show-spinner="spinners.table">
                <template slot="columns">
                    <th data-column="internal_id">
                        <sorting-label title="PN-Nr." field="internal_id" v-model="sorting" />
                        <base-input placeholder="PN-Nr. suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.internal_id" @input="searchData"></base-input>
                    </th>
                    <th data-column="name">
                        <sorting-label title="Name" field="name" v-model="sorting" />
                        <base-input placeholder="Name suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.name" @input="searchData"></base-input>
                    </th>
                    <th data-column="postalcode">
                        <sorting-label title="PLZ" field="postalcode" v-model="sorting" />
                        <base-input placeholder="PLZ suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.postalcode" @input="searchData"></base-input>
                    </th>
                    <th data-column="city">
                        <sorting-label title="Stadt" field="city" v-model="sorting" />
                        <base-input placeholder="Stadt suchen" type="search" inputClasses="form-control-sm" :noMargin="true" v-model="search.city" @input="searchData"></base-input>
                    </th>
                    <th data-column="country">
                        <sorting-label title="Land" field="country" v-model="sorting" />

                        <base-dropdown tag="div">
                            <base-button slot="title" size="sm" type="form-control" class="dropdown-toggle">
                                {{ searchCountry.name }}
                            </base-button>
                            <a class="dropdown-item text-normal" @click="search.country = null, searchData()">Auswahl zurücksetzen</a>
                            <a class="dropdown-item text-normal" v-for="country in countries" :key="country.id" @click="search.country = country.id, searchData()">{{ country.name }}</a>
                        </base-dropdown>
                    </th>
                    <th data-column="accepted_value">
                        <sorting-label title="angenommene Buchungen in %" field="accepted_value" v-model="sorting" />

                        <div class="input-group flex-nowrap">
                            <base-dropdown tag="div">
                                <base-button slot="title" size="sm" type="form-control" class="dropdown-toggle">
                                    {{ search.accepted_operator === 'lte' ? '&lt;=' : '&gt;=' }}
                                </base-button>
                                <a class="dropdown-item text-normal" @click="search.accepted_operator = 'lte', searchData()">&lt;=</a>
                                <a class="dropdown-item text-normal" @click="search.accepted_operator = 'gte', searchData()">&gt;=</a>
                            </base-dropdown>

                            <base-input placeholder="nach % filtern" type="number" inputClasses="form-control-sm" :noMargin="true" v-model.number="search.accepted_value" @input="searchData"></base-input>
                        </div>
                    </th>
                    <th data-column="answer_value">
                        <sorting-label title="durchschnittliche Antwortzeit in Std." field="answer_value" v-model="sorting" />

                        <div class="input-group flex-nowrap">
                            <base-dropdown tag="div">
                                <base-button slot="title" size="sm" type="form-control" class="dropdown-toggle">
                                    {{ search.answer_operator === 'lte' ? '&lt;=' : '&gt;=' }}
                                </base-button>
                                <a class="dropdown-item text-normal" @click="search.answer_operator = 'lte', searchData()">&lt;=</a>
                                <a class="dropdown-item text-normal" @click="search.answer_operator = 'gte', searchData()">&gt;=</a>
                            </base-dropdown>

                            <base-input placeholder="nach Std. filtern" type="number" inputClasses="form-control-sm" :noMargin="true" v-model.number="search.answer_value" @input="searchData"></base-input>
                        </div>
                    </th>
                    <th></th>
                </template>

                <template slot-scope="{row}">
                    <td data-column="internal_id">
                        {{ row.entry.internal_id }}
                    </td>

                    <td data-column="name">
                        {{ row.entry.name }}
                    </td>

                    <td data-column="postalcode">
                        {{ row.entry.postalcode }}
                    </td>

                    <td data-column="cityy">
                        {{ row.entry.city }}
                    </td>

                    <td data-column="country">
                        {{ getCountryName(row.entry.country) }}
                    </td>

                    <td data-column="accepted_value">
                        <span v-if="row.statistics">
                            {{ row.statistics.accepted.percentage }}% ({{ row.statistics.accepted.accepted }}/{{ row.statistics.accepted.total }})
                        </span>
                    </td>

                    <td data-column="answer_value">
                        {{ getAnswerTime(row.statistics.answer_time) }}
                    </td>

                    <td class="d-flex justify-content-end align-items-center">
                        <base-button type="neutral" size="sm" @click="showHotel(row.entry)" title="Hotel bearbeiten">
                            <i class="ni ni-building text-blue"></i>
                        </base-button>

                        <base-button type="neutral" size="sm" @click="showBookings(row.entry)" title="Buchungen anzeigen">
                            <i class="ni ni-tag text-orange"></i>
                        </base-button>

                        <div class="px-2"></div>

                        <base-button size="sm" @click="showEntry(row)">
                            <i class="ni ni-chart-bar-32"></i>
                        </base-button>
                    </td>
                </template>
            </base-table>
        </div>

        <div class="card-footer d-flex justify-content-end">
            <base-pagination v-if="tableData.length && !pagination.showAll" :total="pagination.total" :perPage="pagination.perPage" :value="pagination.value" @input="loadTableData" />
        </div>
    </div>
</template>
<script>
import apiService from '@/apiService'
import SortingLabel from '@/components/Table/SortingLabel.vue';

export default {
    name: 'hotel-statistics-table',
    components: {
        SortingLabel,
    },
    props: {
        title: String,
        startPagination: Object,
        startSearch: Object,
        startSorting: Object,
    },
    data() {
        return {
            spinners: {
                table: false,
            },
            tableData: [],
            table: 'hotel',
            pagination: this.startPagination,
            search: this.startSearch,
            sorting: this.startSorting,
            countries: [],
        }
    },
    computed: {
        searchCountry() {
            return this.countries.find(country => country.id === this.search.country) || {name: 'Land suchen'};
        },
    },
    watch: {
        sorting() {
            this.reload();
        },
    },
    methods: {
        reload() {
            this.loadCountries();

            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData();
            }
        },
        async showAll() {
            this.tableData = [];
            this.spinners.table = true;

            const response = await apiService.getStatisticsForAll(this.table, this.search, 0, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination.showAll = true;
            }

            this.spinners.table = false;
        },
        async loadTableData(page = null) {
            if(this.pagination.showAll) {
                return this.showAll();
            }

            this.tableData = [];
            this.spinners.table = true;

            const pageToLoad = page || this.pagination.value;

            const response = await apiService.getStatisticsForAll(this.table, this.search, pageToLoad, this.sorting);

            if(response !== null) {
                this.tableData = response.data;
                this.pagination = {
                    showAll: false,
                    total: response.total,
                    perPage: response.per_page,
                    value: response.current_page,
                };
            }

            this.spinners.table = false;
        },
        searchData() {
            if(this.pagination.showAll) {
                this.showAll();
            } else {
                this.loadTableData(1);
            }
        },
        showEntry(entry) {
            this.$router.push({name: 'hotel-statistics', params: {
                statistics: entry,
                closeParams: {
                    name: 'statistics',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
        async loadCountries() {
            const countriesResponse = await apiService.loadTableData('country', {}, 0);

            if(countriesResponse !== null) {
                this.countries = countriesResponse.data;
            }
        },
        getCountryName(countryId) {
            const country = this.countries.find(country => country.id === countryId);

            if(typeof country === 'undefined') return '';

            return country.name;
        },
        getAnswerTime(seconds) {
            const pad = number => {
                if(number < 10) return `0${number}`;
                return number;
            };

            const minutes = Math.floor(seconds / 60 % 60);
            const hours = Math.floor(seconds / 60 / 60);

            return `${hours}h ${pad(minutes)}m`;
        },
        async showHotel(entry) {
            const responseData = await apiService.loadEntry('hotel', entry.id);
            
            this.$router.push({name: 'hotel', params: {
                hotel: responseData,
                closeParams: {
                    name: 'statistics',
                    params: {
                        pagination: this.pagination,
                        search: this.search,
                        sorting: this.sorting,
                    },
                },
            }});
        },
        showBookings(entry) {
            this.$router.push({
                name: 'bookings',
                params: {
                    search: {
                        hotel__name: entry.name,
                    },
                },
            });
        },
    },
    created() {
        this.loadCountries();
        this.loadTableData();
    }
}
</script>
<style>
</style>
