<template>
    <div data-view="settings">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <card shadow type="secondary">
                <div slot="header" class="bg-white border-0">
                    <h3 class="mb-0">Einstellungen</h3>
                </div>
                <template>
                    <form @submit="submit">
                        <h6 class="heading-small text-muted mb-4">Adminbereich</h6>

                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label class="form-control-label d-block">Standard Bearbeitungssprache</label>

                                    <base-dropdown tag="div" class="mb-4">
                                        <base-button slot="title" type="form-control-alternative" class="dropdown-toggle">
                                            {{ selectedLanguage.name }}
                                        </base-button>
                                        <a class="dropdown-item" v-for="language in languages" :key="language.short_code" @click="form.defaultEditLanguage = language.short_code">{{ language.name }}</a>
                                    </base-dropdown>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Einträge pro Seite in Tabellen"
                                                placeholder="Einträge pro Seite in Tabellen"
                                                type="number"
                                                input-classes="form-control-alternative"
                                                v-model.number="$v.form.rowsPerTable.$model"
                                                :valid="showErrors ? !$v.form.rowsPerTable.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.rowsPerTable.required">Bitte geben Sie eine Zahl an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-else-if="showErrors && !$v.form.rowsPerTable.between">Bitte geben Sie eine Zahl zwischen 10 und 200 an.</div>
                                </div>
                            </div>
                        </div>

                        <hr class="my-4" />

                        <base-button type="primary" nativeType="submit" icon="ni ni-check-bold">Speichern</base-button>
                    </form>
                </template>
            </card>
        </div>
    </div>
</template>

<script>
import apiService from '@/apiService'
import { required, between } from 'vuelidate/lib/validators'
import userSettings from '@/userSettings'

export default {
    name: 'settings',
    data() {
        return {
            showErrors: false,
            form: {
                defaultEditLanguage: userSettings.getSetting('defaultEditLanguage'),
                rowsPerTable: userSettings.getSetting('rowsPerTable'),
            },
            languages: []
        }
    },
    computed: {
        selectedLanguage() {
            return this.languages.find(language => language.short_code === this.form.defaultEditLanguage) || {short_code: '', name: 'Keine ausgewählt'};
        }
    },
    validations: {
        form: {
            rowsPerTable: {
                required,
                between: between(10, 200),
            },
        },
    },
    methods: {
        submit(event) {
            event.preventDefault();

            this.$v.$touch();

            this.showErrors = this.$v.$invalid;
            
            if(!this.$v.$invalid) {
                userSettings.setSettings(this.form);

                this.$notify({
                    type: 'success',
                    title: 'Einstellungen erfolgreich gespeichert!'
                });
            }
        },
    },
    async created() {
        const response = await apiService.loadTableData('language', {}, 0);

        if(response !== null) {
            this.languages = response.data;
        }
    },
}
</script>

<style>

</style>