<template>
    <div data-view="edit-price">
        <base-header type="gradient-perso" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <card shadow type="secondary" footer-classes="position-sticky bottom-0">
                <div slot="header" class="bg-white border-0 d-flex justify-content-between align-items-center">
                    <h3 class="mb-0">{{ isNew ? 'Neuen Preis anlegen' : 'Preis bearbeiten' }}</h3>

                    <div>
                        <language-menu @change="changeLanguage" />
                    </div>
                </div>
                <template>
                    <form id="edit-form" @submit="submit" @change="formChanged" ref="form">
                        <h6 class="heading-small text-muted mb-4">Allgemein</h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group has-label">
                                        <label class="form-control-label d-block">Währung *</label>

                                        <base-dropdown tag="div">
                                            <base-button slot="title" type="form-control-alternative" class="dropdown-toggle">
                                                {{ form.currency || 'Nicht ausgewählt' }}
                                            </base-button>
                                            <a class="dropdown-item" @click="form.currency = null">Auswahl zurücksetzen</a>
                                            <a class="dropdown-item" @click="form.currency = 'EUR'">EUR</a>
                                            <a class="dropdown-item" @click="form.currency = 'CHF'">CHF</a>
                                        </base-dropdown>
                                    </div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && form.currency === null">Bitte wählen Sie eine Währung aus.</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input label="Einzelzimmerpreis"
                                                placeholder="Einzelzimmerpreis"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model.number="$v.form.price_singleroom.$model"
                                                :valid="showErrors ? !$v.form.price_singleroom.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.price_singleroom.required">Bitte geben Sie einen Einzelzimmerpreis an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.price_singleroom.decimal">Bitte geben Sie eine Zahl an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Doppelzimmerpreis"
                                                placeholder="Doppelzimmerpreis"
                                                input-classes="form-control-alternative"
                                                :required="true"
                                                v-model.number="$v.form.price_doubleroom.$model"
                                                :valid="showErrors ? !$v.form.price_doubleroom.$error : null"
                                    />
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.price_doubleroom.required">Bitte geben Sie einen Doppelzimmerpreis an.</div>
                                    <div class="text-danger invalid-feedback mt--2 mb-3 d-block" v-if="showErrors && !$v.form.price_doubleroom.decimal">Bitte geben Sie eine Zahl an.</div>
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Gültig ab"
                                                type="date"
                                                pattern="\d{4}-\d{2}-\d{2}"
                                                placeholder="Gültig ab (JJJJ-MM-TT)"
                                                input-classes="form-control-alternative"
                                                v-model="form.valid_from"
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <base-input label="Gültig bis"
                                                type="date"
                                                pattern="\d{4}-\d{2}-\d{2}"
                                                :min="form.date_arrival"
                                                placeholder="Gültig bis (JJJJ-MM-TT)"
                                                input-classes="form-control-alternative"
                                                v-model="form.valid_to"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
                <template slot="footer">
                    <base-button type="primary" form="edit-form" nativeType="submit" :icon="spinners.submitButton ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'">
                        Speichern
                    </base-button>

                    <base-button type="primary" form="edit-form" :icon="spinners.submitAndClose ? 'fas fa-circle-notch fa-spin' : 'ni ni-check-bold'" @click="submitAndClose">
                        Speichern &amp; schließen
                    </base-button>

                    <base-button type="link" form="edit-form" icon="ni ni-fat-remove" @click="close">Schließen</base-button>
                </template>
            </card>
        </div>

        <modal :show.sync="languageChanged"
               gradient="primary"
               modal-classes="modal-danger modal-dialog-centered">
            <div class="py-3 text-center">
                <i class="fas fa-save fa-3x"></i>
                <h4 class="heading mt-4">Eintrag speichern bevor die Sprache gewechselt wird?</h4>
                <p class="mb-0">Beim wechseln der Sprache gehen alle nicht gespeicherten Änderungen verloren.</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="saveAndReload">
                    <i v-if="spinners.modalButton" class="fas fa-circle-notch fa-spin"></i>
                    Speichern
                </base-button>
                <base-button type="link"
                             text-color="white"
                             class="ml-auto"
                             @click="reload">
                    Nicht speichern
                </base-button>
            </template>
        </modal>

        <div id="photo-lightbox" v-if="lightbox.show">
            <img class="lightbox-image" :src="lightbox.source">

            <button class="lightbox-close" @click="lightbox.show = false">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
</template>
<script>
import { required, decimal } from 'vuelidate/lib/validators'
import apiService from '@/apiService'
import helper from '@/helper'
import LanguageMenu from '@/views/Dashboard/LanguageMenu.vue'
import eventBus from '@/eventBus'

export default {
    name: 'edit-price',
    components: {
        LanguageMenu,
    },
    props: {
        price: {
            type: Object,
            default() {
                return {
                    id: null,
                    currency: null,
                    price_singleroom: 0,
                    price_doubleroom: 0,
                    valid_from: null,
                    valid_to: null,
                };
            },
        },
        closeParams: {
            type: Object,
            default() {
                return {
                    name: 'prices',
                };
            },
        },
    },
    data() {
        return {
            saveEventId: null,
            closeEventId: null,
            showErrors: false,
            spinners: {
                submitButton: false,
                submitAndClose: false,
                modalButton: false,
            },
            lightbox: {
                show: false,
                source: '',
            },
            languageChanged: false,
            previousLanguage: null,
            cleanForm: {...this.price},
            form: this.price,
        };
    },
    computed: {
        isNew() {
            return this.form.id === null;
        },
    },
    watch: {
        form() {
            this.formChanged();
        },
        cleanForm() {
            this.formChanged();
        },
    },
    validations: {
        form: {
            currency: { required },
            price_singleroom: { required, decimal },
            price_doubleroom: { required, decimal },
            valid_from: { },
            valid_to: { },
        },
    },
    methods: {
        async submit(event = null, language = null) {
            if(event) event.preventDefault();

            this.spinners.submitButton = this.spinners.submitAndClose ? false : true;

            this.$v.$touch();
            
            if(this.$v.$invalid || this.form.currency === null) {
                this.showErrors = true;
            } else {
                let responseData;

                if(this.isNew) {
                    responseData = await apiService.createEntry('price', this.form, language);
                } else {
                    responseData = await apiService.updateEntry('price', this.form.id, this.form, language);
                }

                if(responseData) {
                    this.showErrors = false;
                    this.form = responseData;
                    this.cleanForm = {...responseData};

                    this.$notify({
                        type: 'success',
                        title: 'Daten erfolgreich gespeichert!'
                    });
                }
            }

            this.spinners.submitButton = false;
        },
        formChanged() {
            eventBus.set('unsaveToClose', !helper.deepEqualObjects(this.cleanForm, this.form));
        },
        close() {
            this.$router.push(this.closeParams);
        },
        async saveAndClose() {
            await this.submit();

            return !this.showErrors;
        },
        async submitAndClose() {
            this.spinners.submitAndClose = true;

            await this.submit();
            
            this.spinners.submitAndClose = false;

            if(!this.showErrors) this.close();
        },
        changeLanguage(oldLanguage) {
            this.previousLanguage = oldLanguage;

            if(!helper.deepEqualObjects(this.cleanForm, this.form)) {
                this.languageChanged = true;
            } else {
                this.reload();
            }
        },
        async saveAndReload() {
            this.spinners.modalButton = true;

            await this.submit(null, this.previousLanguage);
            await this.reload();

            this.languageChanged = false;
            this.spinners.modalButton = false;
        },
        async reload() {
            if(this.isNew) {
                this.form = this.price;
                this.cleanForm = {...this.price};
            } else {
                const responseData = await apiService.loadEntry('price', this.form.id);

                if(responseData) {
                    this.showErrors = false;
                    this.form = responseData;
                    this.cleanForm = {...responseData};
                }
            }

            this.languageChanged = false;
        },
    },
    created() {
        this.saveEventId = eventBus.on('save-and-close', async () => {
            const success = await this.saveAndClose();

            eventBus.emit('entry-saved', success);
        });

        this.closeEventId = eventBus.on('prepare-to-close', () => {
            this.formChanged();
        });
    },
    beforeDestroy() {
        eventBus.off('save-and-close', this.saveEventId);
        eventBus.off('prepare-to-close', this.closeEventId);
    },
};
</script>
<style scoped>
</style>
